import { MasterReducer, Generator } from '../../utils/store';
import { call, put, select } from 'redux-saga/effects';

import * as Auths from '../../utils/auth';
import * as Functions from '../../utils/functions';
import * as Constants from '../../utils/constants';
import { getCurrentUser } from '../../api';

export const Reducer = new MasterReducer(
  'app',
  {
    user: false,
    location: false,
    webSocket: false,
    isWebSocketConnected: false,
    activedGroups: [],
    openPopup: false,

  },
  {
    setPopup: (state, { payload }) => {
      console.log('payload', payload);
      return {
        ...state,
        openPopup: payload,
      };
    },
    ...Generator.makeObjectModelActions('user', 'loadUser', function* (returnAction, self) {
      try {
        const response = yield call(getCurrentUser);
        if (response.result) {
          Auths.setUser(response.data);

          if (response.data.actived_profile.actived_groups) {
            yield put(self.actionCreators.setStates({
              activedGroups: response.data.actived_profile.actived_groups
            }));
          }
        }
        else {
          Auths.removeToken();
          Auths.removeUser();
        }

        yield put(returnAction(response));
      } catch (e) {
        if (e.response?.status === 403 || e.code === 'ERR_NETWORK') {
          Auths.removeToken();
          Auths.removeUser();
          const routeTypesWithoutAuth = [
            Constants.Navigation.RouteType.ToAuthenticate.code,
            Constants.Navigation.RouteType.Public.code,
          ];
          const isPathInRoutes = Object.values(Constants.Navigation.Route).some(
            (route) =>
              routeTypesWithoutAuth.includes(route.routeTypeCode) &&
              route.path === window.location.pathname
          );
          if (!isPathInRoutes) window.location.reload(false);
        };
        yield put(returnAction(e));
      }
    }),

    ...Generator.makeActions('updateUser', function* (returnAction, self) {
      try {
        var values = yield select(self.makeSelector('values'));

        const response = yield call(
          Functions.Request.makeRequestCall,
          'PATCH',
          'users/me',
          {
            body: values
          }
        );

        if (response.result) {
          if (values.reloadCallback) {
            values.reloadCallback();
          }
          yield put(self.actionCreators.loadUser());
        }

        yield put(returnAction(response));
      } catch (e) {
        yield put(self.actionCreators.setPopup(true));
        yield put(returnAction(e));
      }
    }),

    ...Generator.makeActions('signOut', function* (returnAction, self) {
      try {
        const response = yield call(
          Functions.Request.makeRequestCall,
          'DELETE',
          'auth/sign-out'
        );

        if (response.result) {
          Auths.removeToken();
          Auths.removeUser();
          yield put(self.actionCreators.loadUser());
        }

        yield put(returnAction(response));
      } catch (e) {
        console.log('response', e);
        yield put(returnAction(e));
      }
    }),

    ...Generator.makeActions('deleteAccount', function* (returnAction, self) {
      // eslint-disable-next-line no-restricted-globals
      let result = confirm("アカウントを削除してもよろしいですか?");
      if (result) {
        try {
          const response = yield call(
            Functions.Request.makeRequestCall,
            'DELETE',
            `users/${Auths.getUser().id}`
          );
  
          if (response.result) {
            Auths.removeToken();
            Auths.removeUser();
            yield put(self.actionCreators.loadUser());
          }
  
          yield put(returnAction(response));
        } catch (e) {
          console.log('response', e);
          yield put(returnAction(e));
        }
      } else {
        yield put(returnAction());
      }
    }),

    ...Generator.makeObjectModelActions('webSocket', 'initializeWebSocket', function* (returnAction, self) {
      try {
        const values = yield select(self.makeSelector('values'));

        const newWebSocket = yield call(
          Functions.WebSocketer.createConnection,
          process.env.REACT_APP_WEB_SOCKET_ENDPOINT + '?Authorization=' + Auths.getToken(),
          values
        );

        newWebSocket.onclose = () => {
          console.log('WebSocket has been closed!');
          console.log('Reconnecting in 3s');
          setTimeout(() => {
            self.dispatcher.initializeWebSocket();
          }, 3000);
        };

        newWebSocket.onerror = (err) => {
          console.log('WebSocket error!', err);
          console.log('Reconnecting in 3s');
          setTimeout(() => {
            self.dispatcher.initializeWebSocket();
          }, 3000);
        };

        newWebSocket.sendRequest = (action, data) => {
          newWebSocket.send(JSON.stringify({
            action: action,
            data: data,
            Authorization: Auths.getToken() || Math.random()
          }))
        };

        yield put(returnAction({
          result: true,
          data: newWebSocket,
        }));
      } catch (e) {
        console.log('response', e);
        yield put(returnAction(e));
      }
    }),

    ...Generator.makeActions('toggleActivedGroup', function* (returnAction, self) {
      try {
        const values = yield select(self.makeSelector('values'));
        var activedGroups = yield select(self.makeSelector('activedGroups'));
        var activedGroupIds = activedGroups.map((x) => x.id);
        if (activedGroupIds.includes(values)) {
          activedGroupIds = Functions.Arrayer.removeElement(activedGroupIds, values);
        }
        else {
          activedGroupIds.push(values);
        }

        const response = yield call(
          Functions.Request.makeRequestCall,
          'PUT',
          'auth/profile',
          {
            body: {
              actived_group_ids: activedGroupIds
            }
          }
        );

        if (response.result) {
          yield put(self.actionCreators.loadUser());
        }

        yield put(returnAction(response));
      } catch (e) {
        console.log('response', e);
        yield put(returnAction(e));
      }
    }),
  },
  true
);

export default Reducer;

export const reducer = Reducer.reducer;
export const saga = Reducer.saga;
