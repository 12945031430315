import {Route, Redirect} from 'react-router-dom';
import {checkAuth, getUser} from './auth';

export const AuthenticatedRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={props => (
        checkAuth()
            ? <Component {...props} />
            : <Redirect to={{pathname: '/intro', state: {from: props.location}}}/>
    )}/>
);

export const ToAuthenticateRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={props => (
        !checkAuth()
            ? <Component {...props} />
            : <Redirect to={{pathname: '/', state: {from: props.location}}}/>
    )}/>
);
export const AdminRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={props => (
        (checkAuth() && getUser().role_user === 'admin') || getUser().role_user === 'manage'
            ? <Component {...props} />
            : <Redirect to={{pathname: '/', state: {from: props.location}}}/>
    )}/>
);