import { call } from 'redux-saga/effects';
import request, { skippedRequest } from './request';
import i18n from './i18n';
import * as Auths from './auth';
import * as Constants from './constants';
import jsQR from 'jsqr';
import ReactDOMServer from 'react-dom/server';
import moment from 'moment';
import Papa from 'papaparse';
import Encoding from 'encoding-japanese';

export const General = {
  getConstant: (path, code) => {
    var result = {
      'name': 'notset',
      'label': 'notset',
      'this': {},
      'list': [],
      'inputList': []
    };

    Object.keys(path).forEach(function (key) {
      let item = path[key];

      // Mapping input list
      result.inputList.push({
        name: item.name,
        value: item.code,
        label: item.label,
        checked: item.code === code ? true : false,
      })

      result.list.push(item);
    });

    if (code || code === 0) {
      Object.keys(path).forEach(function (key) {
        let item = path[key];

        // Set value and label
        if (item.code === code) {
          result.name = item.name;
          result.label = item.label;
          result.this = item;
        }
      });
    }

    return result;
  },
  makeConstant: (textList, prefix) => {
    var result = {};
    textList.forEach((item) => {
      let pattern = prefix + '_' + item;
      result['_' + item] = {
        code: item,
        name: pattern,
        label: pattern,
      }
    });

    return result;
  },
  generatePassword: () => {
    var password = "";
    var length = 3;
    var charset = "notset";

    charset = "abcdefghijklmnopqrstuvwxyz";
    for (var i = 0, q = charset.length; i < length; ++i) {
      password += charset.charAt(Math.floor(Math.random() * q));
    }

    charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    for (var j = 0, w = charset.length; j < length; ++j) {
      password += charset.charAt(Math.floor(Math.random() * w));
    }

    charset = "0123456789";
    for (var k = 0, e = charset.length; k < length; ++k) {
      password += charset.charAt(Math.floor(Math.random() * e));
    }

    // charset = "!@#$%^&*()<>";
    // charset = "@";
    // for (var l = 0, r = charset.length; l < length; ++l) {
    //   password += charset.charAt(Math.floor(Math.random() * r));
    // }

    return password;
  },
};

export const DateTime = {
  addYears: (datetime, years) => {
    var result = new Date(datetime);
    result.setFullYear(result.getFullYear() + years);
    return result;
  },
  addMonths: (datetime, months) => {
    var result = new Date(datetime);
    result.setMonth(result.getMonth() + months);
    return result;
  },
  addDays: (datetime, days) => {
    var result = new Date(datetime);
    result.setDate(result.getDate() + days);
    return result;
  },
  addHours: (datetime, hours) => {
    var result = new Date(datetime);
    result.setHours(result.getHours() + hours);
    return result;
  },
  addSeconds: (datetime, seconds) => {
    var result = new Date(datetime);
    result.setSeconds(result.getSeconds() + seconds);
    return result;
  },
  getMonthFirstDate: (datetime) => {
    return new Date(datetime.getFullYear(), datetime.getMonth(), 1);
  },
  getMonthLastDate: (datetime) => {
    return new Date(datetime.getFullYear(), datetime.getMonth() + 1, 0);
  },
  makeYearMonthInfo: (yearMonth) => {
    if (!yearMonth) {
      return 'yyyy-MM-dd ~ yyyy-MM-dd';
    }

    var calculateDate = new Date(yearMonth);
    var firstDate = DateTime.getMonthFirstDate(calculateDate);
    var lastDate = DateTime.getMonthFirstDate(calculateDate);

    return DateTime.format(firstDate, 'yyyy-MM-dd') + ' ~ ' + DateTime.format(lastDate, 'yyyy-MM-dd');
  },
  makeDatesInMonth: (datetime) => {
    const originDate = new Date(datetime);
    const date = DateTime.getMonthFirstDate(originDate);

    const dates = [];

    while (date.getMonth() === originDate.getMonth()) {
      dates.push({
        id: DateTime.format(date, 'yyyy-MM-dd'),
        date: new Date(date),
        formattedDate: DateTime.format(date, 'yyyy-MM-dd'),
      });
      date.setDate(date.getDate() + 1);
    }

    return dates;
  },
  format_jp: (datetime) => {
    return moment(datetime).format('YYYY年MM月DD日 HH:mm');
  },
  format: (datetime, format) => {
    format = format.replace(/yyyy/g, datetime.getFullYear());
    format = format.replace(/MM/g, ('0' + (datetime.getMonth() + 1)).slice(-2));
    format = format.replace(/M/g, datetime.getMonth() + 1);
    format = format.replace(/dd/g, ('0' + datetime.getDate()).slice(-2));
    format = format.replace(/d/g, datetime.getDate());
    format = format.replace(/HH/g, ('0' + datetime.getHours()).slice(-2));
    format = format.replace(/H/g, datetime.getHours());
    format = format.replace(/mm/g, ('0' + datetime.getMinutes()).slice(-2));
    format = format.replace(/m/g, datetime.getMinutes());
    format = format.replace(/ss/g, ('0' + datetime.getSeconds()).slice(-2));

    return format;
  },
  createByString: (dateString, timeString) => {
    return new Date(`${DateTime.format(new Date(dateString), 'yyyy-MM-dd')} ${timeString}`);
  },
  compareYearMonth: (datetimeA, datetimeB) => {
    return DateTime.format(new Date(datetimeA), 'yyyy-MM') === DateTime.format(new Date(datetimeB), 'yyyy-MM');
  },
  millisToTime: (millis) => {
    if (!millis) {
      return {
        'minutes': 0,
        'seconds': 0,
      };
    }

    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    seconds = (seconds < 10 ? '0' : '') + seconds;

    return {
      'minutes': minutes,
      'seconds': seconds,
    };
  },
  secondsToTime: (inputSeconds) => {
    if (!inputSeconds || inputSeconds === 0) {
      return {
        'hours': '00',
        'minutes': '00',
        'seconds': '00',
        'subSeconds': '00',
        'shortLabel': '00:00',
        'label': '00:00:00.00'
      };
    }

    var hours = Math.floor(inputSeconds / 3600);
    var minutes = Math.floor((inputSeconds - (hours * 3600)) / 60);
    var seconds = Math.floor(inputSeconds - (hours * 3600) - (minutes * 60));
    var subSeconds = Math.floor((inputSeconds % 1).toFixed(2) * 100);

    const format = function (time) {
      if (time === 0) {
        return '00'
      }
      return (time < 10 ? '0' : '') + time;
    }

    hours = format(hours);
    minutes = format(minutes);
    seconds = format(seconds);
    subSeconds = format(subSeconds);

    return {
      'hours': hours,
      'minutes': minutes,
      'seconds': seconds,
      'subSeconds': subSeconds,
      'shortLabel': hours + ':' + minutes,
      'label': hours + ':' + minutes + ':' + seconds + '.' + subSeconds
    };
  },
  roundDate30Minutes: (date) => {
    const minutes = 30;
    const ms = 1000 * 60 * minutes;

    // 👇️ replace Math.round with Math.ceil to always round UP
    return new Date(Math.round(date.getTime() / ms) * ms);
  },
  makeTimeSelectList: () => {
    var result = [];

    for (let i = 0; i < 48; i++) {
      let seconds = i * 1800;
      let time = DateTime.secondsToTime(seconds);
      let value = time.hours + ':' + time.minutes;
      result.push({
        code: value,
        name: "_" + i,
        label: value,
        value: value,
      })
    }

    return result;
  },
  makeDayOfWeekList : () => {
    return [
      1,
      2,
      3,
      4,
      5,
      6,
      0
    ]
  },
  fromNow: (datetime) => {
    var langCode = Browser.getLanguageCode();
    var target = moment(datetime).locale(langCode);

    return target.fromNow();
  },
  secondsDuration: (startDatetime, endDatetime) => {
    if (!startDatetime || !endDatetime) { return 0;}

    var momentStart = moment(new Date(startDatetime));
    var momentEnd = moment(new Date(endDatetime));
    var duration = moment.duration(momentEnd.diff(momentStart));

    return duration.asSeconds();
  },
  timeDuration: (startDatetime, endDatetime) => {
    var secondsDuration = DateTime.secondsDuration(startDatetime, endDatetime);

    return DateTime.secondsToTime(secondsDuration).shortLabel;
  },
};

export const Request = {
  makeFormDataPayload: (payload) => {
    var formData = new FormData();
    for (var key in payload) {
      formData.append(key, Request.convertToFormBool(payload[key]));
    }
    return formData;
  },
  convertToFormBool: (value) => {
    if (value === true) { return 1; }
    if (value === false) { return 0; }
    return value;
  },
  makeJsonPayload: (payload) => {
    Object.keys(payload).forEach((key) => {
      let value = payload[key];
      if (value instanceof Date) {
        payload[key] = DateTime.format(value, 'yyyy-MM-dd HH:mm:ss');
      }
    });
    return JSON.stringify(payload);
  },
  makeRequestCall: function* (method, url, payload = {}) {
    const headers = {
      'Access-Control-Allow-Origin': process.env.REACT_APP_CORS_ORIGIN,
      'Authorization': Auths.getToken() || Math.random(),
      'Content-Type': payload.useFormData ? 'multipart/form-data' : 'application/json',
      'Access-Control-Allow-Credentials': true,
    };

    const requestAdapter = payload.useSkippedRequest ? skippedRequest : request;
    const requestUrl = payload.useRawUrl ? url : Request.getUrl(url, payload.query);
    var response = null;

    switch (method.toUpperCase()) {
      case 'GET':
        response = yield call(requestAdapter, requestUrl, {method: method, headers });
        break;
      default:
        let body = {};
        if (payload.body) {
          body = payload.useFormData ?
          Request.makeFormDataPayload(payload.body) :
          Request.makeJsonPayload(payload.body);
        }
        response = yield call(requestAdapter, requestUrl, {method: method, headers, body});
        break;
    }
    response = Transcription.transcribeResponse(response);
    return response;
  },
  getCsrfToken: function* () {
    const requestURL = process.env.REACT_APP_API_ENDPOINT + 'account/csrf';
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };

    // hit api
    const payload = yield call(request, requestURL, requestOptions);

    return payload.result ? payload.data.csrf_token : 'notset';
  },
  getUrl: (endpoint, request) => {
    if (!request) {
      return process.env.REACT_APP_API_ENDPOINT + endpoint;
    }

    var pagingString = !Stringer.isEmpty(request.page) ?
      'page=' + Number(request.page + 1) : '';
    var sortString = !Stringer.isEmpty(request.sortOrder) ?
      'sort=' + request.sortOrder.name +
      '&direction=' + request.sortOrder.direction : '';
    var limitString = !Stringer.isEmpty(request.limit) ?
      'limit=' + Number(request.limit) : '';

    var filterString = '';
    var filterList = request.filterList ? request.filterList : [];
    filterList.forEach(function (item, index) {
      if (item.length !== 0) {
        filterString += index === 0 ? '' : '&';
        filterString += 'filter_' + request.columns[index].name + '=' + item[0];
      }
    });

    if (pagingString === '' && sortString === '' && limitString === '' && filterString === '') {
      return process.env.REACT_APP_API_ENDPOINT + endpoint;
    }

    return process.env.REACT_APP_API_ENDPOINT + endpoint + '?' + pagingString + sortString + limitString + filterString;
  }
};

export const Response = {
  isValidatorFailed: (fieldName, alert) => {
    if (!alert) {
      return false;
    }
    if (alert.type !== "validate") {
      return false;
    }

    var isInvalid = false;
    Object.keys(alert.validators).forEach(function (key) {
      if (key === fieldName) {
        isInvalid = true;
      }
    });

    return isInvalid;
  },
};

export const Stringer = {
  isEmpty: (obj) => {
    if (!obj) {
      return true;
    }
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return JSON.stringify(obj) === JSON.stringify({});
  },
  makeAbbreviation: (value, digits = 1) => {
    var number = Numberer.isFloat(value) ? Math.round(value) : value;

    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function (item) {
      return number >= item.value;
    });
    return item ? (number / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : number;
  },
  applyMask: (str, mask) => {
    if (!str || !mask) { return ''; }

    let i = 0;
    return mask.replaceAll("#", () => str[i++] || "");
  },
  substringBetween: (str, start, end) => {
    return str.split(start).pop().split(end)[0];
  },
  convertToHalfWidth: (str) => {
    return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    });
  },
  stripNonNumeric: (str) => {
    return str.replace(/\D/g, '');
  },
  containsJapaneseCharacters: (str) => {
    const pattern = /[\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf]/
                  // -------------_____________-------------_____________-------------_____________
                  // Punctuation   Hiragana     Katakana    Full-width       CJK      CJK Ext. A
                  //                                          Roman/      (Common &      (Rare)
                  //                                        Half-width    Uncommon)
                  //                                         Katakana

    return pattern.test(str);
  }
};

export const Numberer = {
  isFloat: (n) => {
    return Number(n) === n && n % 1 !== 0;
  },
  thousandSeparator: (number) => {
    if (number) {
      number = Number(number);
      return (number).toLocaleString('en');
    }

    return number;
  },
  makeCurrency: (amount, prefix, suffix) => {
    var result = Numberer.thousandSeparator(amount);

    if (prefix) {
      result = prefix + result;
    }
    if (suffix) {
      result = result + suffix;
    }

    return result;
  },
  isNumeric: (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
  },
  convertToNumeric: (str) => {
    var result = '';
    var raw = str.normalize('NFKC');
    for (var i = 0; i < raw.length; i++) {
      if (Numberer.isNumeric(raw[i])) {
        result += raw[i];
      }
    }

    return result;
  },
};

export const Arrayer = {
  removeElement: (arr, id) => {
    for (var i = 0; i < arr.length; i++) {

      if (arr[i].id === id || arr[i] === id) {
        arr.splice(i, 1);
      }
    }

    return arr;
  },
  groupBy: (arr, property) => {
    return arr.reduce(function (memo, x) {
      if (!memo[x[property]]) { memo[x[property]] = []; }
      memo[x[property]].push(x);
      return memo;
    }, {});
  },
  distinct: (arr, key) => {
    return [...new Map(arr.map(item =>
      [item[key], item])).values()];
  },
  intersection: (arr1, arr2) => {
    return arr1.filter(value => arr2.includes(value));
  },
};

export const Objecter = {
  assignWithInterface: (pattern, data, newData) => {
    var result = {};

    Object.keys(pattern).forEach(function (patternKey) {
      // assign with data
      result[patternKey] =
        data.hasOwnProperty(patternKey) ?
        data[patternKey] :
        pattern[patternKey];

      // assign with new data
      if (newData.hasOwnProperty(patternKey)) {
        result[patternKey] = newData[patternKey];
      }
    });

    return result;
  },
};

export const Formik = {
  fillFormData: (initialValues, formData) => {
    Object.keys(initialValues).forEach(function (key) {
      initialValues[key] = formData.hasOwnProperty(key) ? formData[key] : initialValues[key];
    });

    return initialValues;
  },
  makeInitialValues: (defaultValues, data) => {
    return { ...defaultValues, ...data };
  },
  makeAdapterFieldValues: (name, data, splittedNames) => {
    var result = {};
    result[name] = data ? data : [];

    if (data) {
      data.forEach((x, index) => {
        if (splittedNames && splittedNames?.length > 0) {
          splittedNames.forEach((y) => {
            result[name + '_' + y + '_' + index] = x[y];
          });
        }
        else {
          result[name + '_' + index] = x;
        }
      });
    }

    return result;
  },
  makeListFieldValues: (name, data, splittedNames) => {
    var result = {};
    result[name] = data ? data : [];

    if (data) {
      data.forEach((x, index) => {
        if (splittedNames && splittedNames?.length > 0) {
          splittedNames.forEach((y) => {
            result[name + '_' + y + '_' + index] = x[y];
          });
        }
        else {
          result[name + '_' + index] = x;
        }
      });
    }

    return result;
  },
  makeSubFieldValues: (name, data, splittedNames) => {
    var result = {};
    var validSubCount = 0;
    result[name] = "";

    splittedNames.forEach((x) => {
      let value = data[name + '_' + x]
      result[name] += value;
      result[name + '_' + x] = value;

      if (value) { validSubCount++ }
    });

    if (validSubCount < splittedNames.length) {
      result[name] = "";
    }

    return result;
  },
};

export const File = {
  blobToFile: (theBlob, fileName) => {
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  },
  dataUriToBlob: (dataURI) => {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    //Old Code
    //write the ArrayBuffer to a blob, and you're done
    //var bb = new BlobBuilder();
    //bb.append(ab);
    //return bb.getBlob(mimeString);

    //New Code
    return new Blob([ab], { type: mimeString });
  },
  dataUriToFile: (dataUri, fileName) => {
    var theBlob = File.dataUriToBlob(dataUri);

    return File.blobToFile(theBlob, fileName);
  },
  generateFilename: (file, requestType) => {
    var date = new Date();
    var year = date.getFullYear();
    var month = ('0' + (date.getMonth() + 1)).slice(-2);
    var day = ('0' + date.getDate()).slice(-2);
    var hour = ('0' + date.getHours()).slice(-2);
    var minutes = ('0' + date.getMinutes()).slice(-2);
    var secconds = ('0' + date.getSeconds()).slice(-2);
    var milliseconds = date.getMilliseconds();

    if (file) {
      var nameFragment = file.name.split('.');
      var type = nameFragment[nameFragment.length - 1]

      return year + '-' + month + '-' + day + '_' +
        hour + '-' + minutes + '-' + secconds + '_' + milliseconds + '.' + type;
    }

    if (requestType) {
      return year + '-' + month + '-' + day + '_' +
        hour + '-' + minutes + '-' + secconds + '_' + milliseconds + '.' + requestType;
    }

    return year + '-' + month + '-' + day + '_' +
      hour + '-' + minutes + '-' + secconds + '_' + milliseconds;
  },
  uploadFiles: function* (type, files, refId) {
    try {
      let filePostList = [];
      for (let i = 0; i < files.length; i++) {
        let file = files[i];

        let fileName = File.generateFilename(file);
        let path = type.path +
          refId + '/' +
          fileName;
        let presigneResult = yield call(S3.presignedUpload,
          file,
          path,
          fileName,
          "/*"
        );
        filePostList.push({
          ref_type: type.code,
          ref_id: refId,
          path: presigneResult.path,
          file_name: presigneResult.fileName,
          file_type: fileName.split('.')[1],
          status_code: Constants.File.Status.New.code,
        });
      }

      if (filePostList.length > 0) {
        const response = yield call(
          Request.makeRequestCall,
          'POST',
          'files',
          {
            body: filePostList
          }
        );

        return response.data;
      }

      return [];
    } catch (e) {
      console.log('Error', e);
      return [];
    }
  },
  makeFilePresignedPath: function* (type, file, refId) {
    try {
      let fileName = File.generateFilename(file);
      let path = type.path +
        refId + '/' +
        fileName;
      const presigneResult = yield call(S3.presignedUpload,
        file,
        path,
        fileName,
        "/*"
      );
      return presigneResult.path;
    } catch (e) {
      console.log('Error', e);
      return "";
    }
  },
};

export const Browser = {
  getUrlSearchParam: (name) => {
    const params = new URLSearchParams(window.location.search);

    if (!params.has(name)) {
      return false;
    }

    const result = Numberer.isNumeric(params.get(name)) ? Number(params.get(name)) : params.get(name);

    return result;
  },
  makeUrlSearchParams: (payload) => {
    if (!payload) return '';

    Object.keys(payload).forEach(function (key) {
      let item = payload[key];
      if (Array.isArray(item)) {
        payload[key] = item.toString();
      }
    });

    return new URLSearchParams(payload).toString();
  },

  createSearchParams: (paramsObject) => {
    const searchParams = new URLSearchParams();
    for (const [key, value] of Object.entries(paramsObject)) {
      Array.isArray(value)
        ? value.forEach((item) => searchParams.append(key, item))
        : searchParams.append(key, value);
    }
    return searchParams.toString();
  },

  getLocationUrl: () => {
    const href = window.location.href;
    const parts = href.split('//')[1].split('/');

    return window.location.protocol + '//' + parts[0];
  },
  isMobileDevice: () => {
    return window.screen.width < 800;
  },
  getLanguageCode: () => {
    const i18Code = localStorage.getItem("i18nextLng");
    const langList = General.getConstant(Constants.General.Locale).list;
    const codeExisting = langList.some(item => item.code === i18Code);
    const defaultCode = process.env.REACT_APP_DEFAULT_LANGUAGE_CODE;
    return codeExisting ? i18Code : defaultCode;
  },
  requestFullscreen: (element) => {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
  },
  initializeVideoStream: async (playerKey, facingMode) => {
    return new Promise((resolve, reject) => {
      const player = document.getElementById(playerKey);

      navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: facingMode,
        }
      }).then(function (stream) {
        player.srcObject = stream;
        player.setAttribute('playsinline', true);
        player.play();
        resolve({
          stream: stream,
          player: player
        });
      });
    });
  },
  initializeAudioStream: async () => {
    return new Promise((resolve, reject) => {
      navigator.mediaDevices.getUserMedia({
        audio: {
          sampleSize: 16,
          channelCount: 1,
          sampleRate: 48000
        }
      }).then(function (stream) {
        var recorder = new MediaRecorder(stream);

        resolve({
          stream: stream,
          recorder: recorder
        });
      });
    });
  },
  initializeQrScannerStream: async (canvasKey, onResultCallback) => {
    const video = document.createElement('video');
    const canvasElement = document.getElementById(canvasKey);
    const canvas = canvasElement.getContext('2d');

    const tick = () => {
      if (video.readyState === video.HAVE_ENOUGH_DATA) {
        canvasElement.height = video.videoHeight;
        canvasElement.width = video.videoWidth;
        canvas.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);
        var imageData = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height);
        var code = jsQR(imageData.data, imageData.width, imageData.height, {
          inversionAttempts: 'dontInvert'
        });

        if (code) {
          onResultCallback(code.data);
        }
      }

      requestAnimationFrame(tick);
    };

    return new Promise((resolve, reject) => {
      navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: 'environment'
        }
      }).then(function (stream) {
        video.srcObject = stream;
        video.setAttribute('playsinline', true);
        video.play();
        requestAnimationFrame(tick);

        resolve({
          stream: stream,
          video: video
        });
      });
    });
  },
};

export const Csv = {
  getData: async (file, header) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async (e) => {
        try {
          const codes = new Uint8Array(e.target.result);
          const encoding = Encoding.detect(codes);
          const unicodeString = Encoding.convert(codes, {
            to: 'unicode',
            from: encoding,
            type: 'string',
          });
          Papa.parse(unicodeString, {
            header: header,
            dynamicTyping: true,
            skipEmptyLines: true,
            complete: (results) => {
              resolve(results.data);
            },
          });
        } catch (err) {
          reject(err);
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsArrayBuffer(file);
    });
  }
}

export const GoogleMap = {
  getMaps: () => {
    // warning: wrap component in @googlemaps/react-wrapper first
    return window.google.maps;
  },
  fetchGeocode: function* (params) {
    params.key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    params.language = 'ja';
    var searchParams = new URLSearchParams(params).toString();
    searchParams += "&latlng=" + params.lat + ',' + params.lng;

    const requestURL = process.env.REACT_APP_GOOGLE_MAPS_GEOCODE_ENDPOINT + '?' + searchParams;
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };

    // hit api
    const response = yield call(request, requestURL, requestOptions);
    var result = {};

    if (response.status === "OK") {
      result = response.results[0] ? response.results[0] : {};
    }

    return result;
  },
  getMapCenterPosition: (map) => {
    return {
      lat: map.getCenter().lat(),
      lng: map.getCenter().lng(),
    };
  },
  mapGeocode: async (maps, payload) => {
    // https://developers.google.com/maps/documentation/javascript/geocoding
    const geocoder = new maps.Geocoder();

    return new Promise((resolve) => geocoder.geocode(payload, (results, status) => {
      if (status === 'OK') {
        var result = results[0];
        var countryComponent = result.address_components.filter(item => item.types.includes('country'))[0];
        var country = countryComponent ? countryComponent.long_name : '';
        result.sliced_formatted_address = result.formatted_address.replace(country + '、', '');
        resolve(result);
      }
      else {
        console.log('Geocode was not successful for the following reason: ' + status);
        resolve({});
      }
    }))
  },
  makeMarkerIcon: (fillColor, fillOpacity) => {
    // https://developers.google.com/maps/documentation/javascript/examples/marker-modern
    // https://developers.google.com/maps/documentation/javascript/custom-markers
    // https://stackoverflow.com/questions/37441729/google-maps-custom-label-x-and-y-position
    // https://stackoverflow.com/questions/33321440/google-maps-api-add-custom-svg-marker-with-label

    return {
      path: `M13.04,41.77c-0.11-1.29-0.35-3.2-0.99-5.42c-0.91-3.17-4.74-9.54-5.49-10.79c-3.64-6.1-5.46-9.21-5.45-12.07
      c0.03-4.57,2.77-7.72,3.21-8.22c0.52-0.58,4.12-4.47,9.8-4.17c4.73,0.24,7.67,3.23,8.45,4.07c0.47,0.51,3.22,3.61,3.31,8.11
      c0.06,3.01-1.89,6.26-5.78,12.77c-0.18,0.3-4.15,6.95-5.1,10.26c-0.64,2.24-0.89,4.17-1,5.48C13.68,41.78,13.36,41.78,13.04,41.77z
      `,
      fillColor: fillColor ? fillColor : '#ff0000',
      fillOpacity: fillOpacity ? fillOpacity : 1,
      strokeColor: '#ffffff',
      strokeWeight: 1,
      anchor: {
        x: 27 / 2, // width / 2
        y: 43, // height
      },
      labelOrigin: {
        x: 13.5,
        y: 15
      },
    };
  },
  makeMarkerLabel: (text, color, fontSize) => {
    return {
      text: text ? text : 'M',
      color: color ? color : "#fff",
      fontSize: fontSize ? fontSize : "10px"
    };
  },
  makeDisplayCoordinate: (coordinate) => {
    return '@' + coordinate?.lat + ',' + coordinate?.lng + ',15z';
  },
  makeCoordinateUrl: (coordinate, address) => {
    let displayCoordinate = GoogleMap.makeDisplayCoordinate(coordinate);
    let searchCoordinate = `${coordinate?.lat},${coordinate?.lng}`;
    let endpoint = process.env.REACT_APP_GOOGLE_MAPS_COORDINATE_SEARCH_ENDPOINT
    return `${endpoint}${searchCoordinate}/${displayCoordinate}`;
  },
  makeInfoWindow: (maps, element) => {
    const makeElementString = (el) => {
      return ReactDOMServer.renderToString(
        <div className='info-window map-child'>
          {el}
        </div>
      )
    };

    const infoWindow = new maps.InfoWindow({
      content: makeElementString(element),
    });

    infoWindow.setContentElement = (el) => {
      infoWindow.setContent(makeElementString(el));
    }

    return infoWindow;
  },
  makePointMarker: (maps, map, payload) => {
    var marker = new maps.Marker({
      draggable: true,
      animation: payload.animation,
      position: payload.position,
      icon: payload.icon,
      label: payload.label,
    });
    marker.addListener("click", () => {
      if (payload.infoWindow) {
        payload.infoWindow.open({
          anchor: marker,
          map,
          shouldFocus: false,
        });
      }
    });

    return marker;
  },
  initializeMapSearchBox: (maps, map) => {
    const existingSearchBox = document.querySelector('.search-box-input');
    if (existingSearchBox) { return false; }

    const input = document.createElement('input');
    input.setAttribute("type", "text");
    input.setAttribute("class", "search-box-input");
    const searchBox = new maps.places.SearchBox(input);
    map.controls[maps.ControlPosition.TOP_CENTER].push(input);
    map.addListener("bounds_changed", () => {
      searchBox.setBounds(map.getBounds());
    });
    searchBox.addListener("places_changed", () => {
      const places = searchBox.getPlaces();

      if (places.length === 0) {
        return;
      }

      // For each place, get the icon, name and location.
      const bounds = new maps.LatLngBounds();

      places.forEach((place) => {
        if (!place.geometry || !place.geometry.location) {
          console.log("Returned place contains no geometry");
          return;
        }

        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
          map.setCenter(place.geometry.location);
          map.setZoom(15);
        }
      });
      map.fitBounds(bounds);
    });
  }
};

export const WebSocketer = {
  createConnection: async (endpoint, callbacks) => {
    return new Promise(function (resolve, reject) {
      const socket = new WebSocket(endpoint);
      socket.onclose = function () {
        console.log('WebSocket has been closed!');
      };
      socket.onmessage = function (e) {
        const data = JSON.parse(e.data);
        console.log('WebSocket message received: ', data);
        if (callbacks?.onmessage) {
          callbacks.onmessage(e);
        }
      };
      socket.onopen = function () {
        console.log('WebSocket has been connected!');
        resolve(socket);
      };
      socket.onerror = function (err) {
        reject(err);
      };
    });
  },
};

export const Transcription = {
  transcribeParams: (params) => {
    var result = [];
    params.forEach((item) => {
      if (typeof item === 'string') {
        if (item.includes('date(')) {
          let dateStr = Stringer.substringBetween(item, 'date(', ')');
          let date = new Date(dateStr);
          result.push(DateTime.format(date, 'yyyy-MM-dd'));
        }
        else if (item.includes('datetime(')) {
          let dateStr = Stringer.substringBetween(item, 'datetime(', ')');
          let date = new Date(dateStr);
          result.push(DateTime.format(date, 'yyyy-MM-dd HH:mm'));
        }
        else {
          result.push(item);
        }
      }
      else {
        result.push(item);
      }
    })
    return result;
  },
  transcribeString: (plainText) => {
    var result = plainText;
    var plainTextFragment = plainText.split('___')

    if (plainTextFragment[0] === '@t') {
      var params = plainTextFragment[2] ? plainTextFragment[2].split(',') : [];
      params = Transcription.transcribeParams(params);
      result = i18n.t(plainTextFragment[1], params);
    }

    return result;
  },
  transcribeObject: (object) => {
    Object.keys(object).forEach((key) => {
      let item = object[key];

      if (typeof item === 'string') {
        object[key] = Transcription.transcribeString(item);
      }
      if (typeof item === 'object' && item) {
        object[key] = Transcription.transcribeObject(item);
      }
    });

    return object;
  },
  transcribeResponse(response) {
    if (!response || !response.data) { return response; }

    var data = response.data;
    if (typeof data === 'object' || Array.isArray(data)) {
      Object.keys(data).forEach((key) => {
        let item = data[key];

        if (typeof item === 'string') {
          data[key] = Transcription.transcribeString(item);
        }
        else if (typeof item === 'object' && item) {
          data[key] = Transcription.transcribeObject(item);
        }
      });
    }

    response.data = data;

    return response;
  },
};

export const S3 = {
  presignedUpload: function* (file, path, fileName, contentType) {
    const requestContentType = contentType ? contentType : 'image/*';

    const presignedUrl = yield call(
      Request.makeRequestCall,
      'POST',
      's3/presigned-url',
      {
        body: {
          key: path,
          content_type: requestContentType
        },
      }
    );

    if (presignedUrl.data) {
      const headers = {
        'Content-Type': requestContentType,
      };
      yield call(skippedRequest, presignedUrl.data, {
        method: 'PUT',
        headers: headers,
        body: file,
      });

      return {
        "fileName": fileName,
        "path": path
      };
    }

    return false;
  },
  getUrl: (path) => {
    return path ? process.env.REACT_APP_AWS_S3_URL + path : '';
  },
};

export const Calendar = {
  isRepeatDeleted: (startAt, event) => {
    let result = false;
    Array.isArray(event.repeat_deletes) && event.repeat_deletes.forEach((item) => {
      switch (item.type_code) {
        case Constants.Event.DeleteType.DeleteThisEvent.code:
          if (new Date(item.start_at).getTime() === new Date(startAt).getTime()) {
            result = true;
          }
          break;
        case Constants.Event.DeleteType.DeleteFromThisEvent.code:
          if (new Date(item.start_at) <= new Date(startAt)) {
            result = true;
          }
          break;
        default:
          break;
      }
    })
    return result;
  },
  getRepeatUpdatedEvent: (newEvent, event) => {
    let result = newEvent;
    Array.isArray(event.repeat_updates) && event.repeat_updates.forEach((item) => {
      switch (item.type_code) {
        case Constants.Event.EditType.EditAll.code:
          let { start_at, end_at, ...otherChanges } = item.changes;
          result = Object.assign(result, otherChanges);
          break;
        case Constants.Event.EditType.EditThisEvent.code:
          if (new Date(item.start_at).getTime() === new Date(newEvent.start_at).getTime()) {
            result = Object.assign(result, item.changes);
          }
          break;
        default:
          break;
      }
    })
    return result;
  },
  makeRepeatEvents: (events) => {
    var result = events;
    var repeatEvents = [];
    result.forEach((event) => {
      let limitDateTime = DateTime.addYears(new Date(event.start_at), 1);
      let addDays = 0;
      switch (event.repeat_code) {
        case Constants.Event.Repeat.NotRepeating.code:
          event.isRepeatOrigin = false;
          break;
        case Constants.Event.Repeat.Everyday.code:
          addDays = 0;
          for (let i = new Date(event.start_at); i < limitDateTime; i.setDate(i.getDate() + 1)) {
            let newEvent = Object.assign({}, event);
            newEvent.start_at = DateTime.addYears(newEvent.start_at, addDays);
            newEvent.end_at = DateTime.addYears(newEvent.end_at, addDays);
            addDays++;
            if (!Calendar.isRepeatDeleted(newEvent.start_at, event)) {
              newEvent = Calendar.getRepeatUpdatedEvent(newEvent, event);
              repeatEvents.push(newEvent);
            }
          }
          event.isRepeatOrigin = true;
          break;
        case Constants.Event.Repeat.EachWeekXDayOfWeek.code:
          addDays = 0;
          for (let i = new Date(event.start_at); i < limitDateTime; i.setDate(i.getDate() + 1)) {
            let newEvent = Object.assign({}, event);
            newEvent.start_at = DateTime.addDays(newEvent.start_at, addDays);
            newEvent.end_at = DateTime.addDays(newEvent.end_at, addDays);
            addDays++;
            if (new Date(event.start_at).getDay() === new Date(newEvent.start_at).getDay()) {
              if (!Calendar.isRepeatDeleted(newEvent.start_at, event)) {
                newEvent = Calendar.getRepeatUpdatedEvent(newEvent, event);
                repeatEvents.push(newEvent);
              }
            }
          }
          event.isRepeatOrigin = true;
          break;
        case Constants.Event.Repeat.Weekdays.code:
          addDays = 0;
          for (let i = new Date(event.start_at); i < limitDateTime; i.setDate(i.getDate() + 1)) {
            let newEvent = Object.assign({}, event);
            newEvent.start_at = DateTime.addDays(newEvent.start_at, addDays);
            newEvent.end_at = DateTime.addDays(newEvent.end_at, addDays);
            addDays++;
            if (new Date(newEvent.start_at).getDay() !== 0 && new Date(newEvent.start_at).getDay() !== 6) {
              if (!Calendar.isRepeatDeleted(newEvent.start_at, event)) {
                newEvent = Calendar.getRepeatUpdatedEvent(newEvent, event);
                repeatEvents.push(newEvent);
              }
            }
          }
          event.isRepeatOrigin = true;
          break;
        case Constants.Event.Repeat.EveryYear.code:
          limitDateTime = DateTime.addYears(new Date(event.start_at), 5);
          let addYears = 0;
          for (let i = new Date(event.start_at); i < limitDateTime; i.setFullYear(i.getFullYear() + 1)) {
            let newEvent = Object.assign({}, event);
            newEvent.start_at = DateTime.addYears(newEvent.start_at, addYears);
            newEvent.end_at = DateTime.addYears(newEvent.end_at, addYears);
            addYears++;
            if (!Calendar.isRepeatDeleted(newEvent.start_at, event)) {
              newEvent = Calendar.getRepeatUpdatedEvent(newEvent, event);
              repeatEvents.push(newEvent);
            }
          }
          event.isRepeatOrigin = true;
          break;
        default:
          break;
      }
    });
    result = result.filter((event) => !event.isRepeatOrigin);
    result.push(...repeatEvents);

    return result;
  },
  filterActiveGroup: (state, setState, item) => {
    const idx = state.findIndex((x) => x === item.id);
    if (state.length === 0 || idx === -1) {
      setState((x) => [...x, item.id]);
    } else {
      setState((x) => x.filter((it) => it !== item.id));
    }
  }
};

export const TimeCard = {
  getListByDate: (timeCards, date) => {
    return timeCards.filter(x =>
      x.time_card_date === date
    );
  },
  getAtWorkByDate: (timeCards, date) => {
    return TimeCard.getListByDate(timeCards, date).find(x =>
      x.type_code === Constants.TimeCard.Type.AtWork.code
    );
  },
  getLeavingWorkByDate: (timeCards, date) => {
    return TimeCard.getListByDate(timeCards, date).find(x =>
      x.type_code === Constants.TimeCard.Type.LeavingWork.code
    );
  },
  getTakingABreakListByDate: (timeCards, date) => {
    return TimeCard.getListByDate(timeCards, date).filter(x =>
      x.type_code === Constants.TimeCard.Type.TakingABreak.code
    );
  },
  getGettingBackListByDate: (timeCards, date) => {
    return TimeCard.getListByDate(timeCards, date).filter(x =>
      x.type_code === Constants.TimeCard.Type.GettingBack.code
    );
  },
  getBreakingSecondsByDate: (timeCards, date) => {
    var gettingBackTimeCards = TimeCard.getGettingBackListByDate(timeCards, date);
    var pairs = [];
    var secondsDuration = 0;

    gettingBackTimeCards.forEach((x) => {
      var takingABreakTimeCard = TimeCard.getTakingABreakListByDate(timeCards, date).find(y =>
        y.id === x.ref_id
      );

      if (takingABreakTimeCard) {
        pairs.push([
          takingABreakTimeCard,
          x,
        ]);
      }
    });
    pairs.forEach((x) => {
      secondsDuration += DateTime.secondsDuration(x[0].actived_at, x[1].actived_at)
    });

    return secondsDuration;
  },
  getWorkingSecondsByDate: (timeCards, date) => {
    var takingABreakSeconds = TimeCard.getBreakingSecondsByDate(timeCards, date);
    var atWorkSeconds = DateTime.secondsDuration(
      TimeCard.getAtWorkByDate(timeCards, date)?.actived_at,
      TimeCard.getLeavingWorkByDate(timeCards, date)?.actived_at
    );

    return atWorkSeconds - takingABreakSeconds;
  },
  isNormalByDate: (timeCards, date) => {
    var atWorkTimeCard = TimeCard.getAtWorkByDate(timeCards, date);
    var leavingWorkTimeCard = TimeCard.getLeavingWorkByDate(timeCards, date);
    var takingABreakCards = TimeCard.getTakingABreakListByDate(timeCards, date);
    var gettingBackTimeCards = TimeCard.getGettingBackListByDate(timeCards, date);
    const validBreakingTimeCards = () => {
      let valid = true;
      [
        ...takingABreakCards,
        ...gettingBackTimeCards,
      ].forEach(x => {
        if (
          x.actived_at <= atWorkTimeCard?.actived_at ||
          x.actived_at >= leavingWorkTimeCard?.actived_at
        ) {
          valid = false;
        }
      })

      return valid;
    };

    const validAtWorkPair =
      (atWorkTimeCard && leavingWorkTimeCard) ||
      (!atWorkTimeCard && !leavingWorkTimeCard);
    const validTakingABreakPairs =
      takingABreakCards.length === gettingBackTimeCards.length;
    const validBreakingTime = TimeCard.getBreakingSecondsByDate(timeCards, date) >= 0;
    const validWorkingTime =
      (atWorkTimeCard && leavingWorkTimeCard) ?
      (atWorkTimeCard.actived_at < leavingWorkTimeCard.actived_at) &&
      validBreakingTimeCards() :
      true;

    return validAtWorkPair &&
      validTakingABreakPairs &&
      validBreakingTime &&
      validWorkingTime;
  },

  getListByYearMonth: (timeCards, yearMonth) => {
    return timeCards.filter(
      x => DateTime.compareYearMonth(x.time_card_date, yearMonth)
    );
  },
  getHolidayListByYearMonth: (yearMonth, payload) => {
    var dateList = DateTime.makeDatesInMonth(yearMonth);
    dateList = dateList.filter(x => {
      return payload.paid_holidays
        .map(y => DateTime.format(new Date(y.acquired_at), 'yyyy-MM-dd'))
        .includes(x.formattedDate) ||
        payload.holiday_day_codes?.includes(x.date.getDay())
    });
    return dateList;
  },
  getAtWorkCountByYearMonth: (timeCards, yearMonth) => {
    var timeCardsByYearMonth = TimeCard.getListByYearMonth(timeCards, yearMonth);
    var atWorkTimeCards = timeCardsByYearMonth.filter(x => x.type_code === Constants.TimeCard.Type.AtWork.code);
    var leavingWorkTimeCards = timeCardsByYearMonth.filter(x => x.type_code === Constants.TimeCard.Type.LeavingWork.code);

    if (atWorkTimeCards.length > leavingWorkTimeCards.length) {
      return leavingWorkTimeCards.length;
    }
    return atWorkTimeCards.length;
  },
  getAbsenceCountByYearMonth: (timeCards, yearMonth, payload) => {
    var dateList = DateTime.makeDatesInMonth(yearMonth);
    var holidayList = TimeCard.getHolidayListByYearMonth(yearMonth, payload);
    var atWorkCount = TimeCard.getAtWorkCountByYearMonth(timeCards, yearMonth);

    return dateList.length - holidayList.length - atWorkCount;
  },
  getBeLateCountByYearMonth: (timeCards, yearMonth, payload) => {
    var timeCardsByYearMonth = TimeCard.getListByYearMonth(timeCards, yearMonth);
    var atWorks = timeCardsByYearMonth.filter(x =>
      x.type_code === Constants.TimeCard.Type.AtWork.code
    );
    var beLateAtWorks = atWorks.filter(x => {
      return DateTime.format(new Date(x.actived_at), 'HH:mm') > payload.working_start_at;
    });

    return beLateAtWorks.length;
  },
  getLeavingEarlyCountByYearMonth: (timeCards, yearMonth, payload) => {
    var timeCardsByYearMonth = TimeCard.getListByYearMonth(timeCards, yearMonth);
    var leavingWorks = timeCardsByYearMonth.filter(x =>
      x.type_code === Constants.TimeCard.Type.LeavingWork.code
    );
    var leavingEarlyTimeCards = leavingWorks.filter(x => {
      return DateTime.format(new Date(x.actived_at), 'HH:mm') < payload.working_end_at
    });

    return leavingEarlyTimeCards.length;
  },
  getHolidayAtWorkCountByYearMonth: (timeCards, yearMonth, payload) => {
    var timeCardsByYearMonth = TimeCard.getListByYearMonth(timeCards, yearMonth);
    var holidayList = TimeCard.getHolidayListByYearMonth(yearMonth, payload);
    var atWorks = timeCardsByYearMonth.filter(x =>
      x.type_code === Constants.TimeCard.Type.AtWork.code
    );

    return atWorks.filter(x =>
      holidayList.map(y => y.formattedDate).includes(DateTime.format(new Date(x.time_card_date), 'yyyy-MM-dd'))
    ).length;
  },
  getWorkingSecondsByYearMonth: (timeCards, yearMonth) => {
    var timeCardsByYearMonth = TimeCard.getListByYearMonth(timeCards, yearMonth);
    var dateList = DateTime.makeDatesInMonth(yearMonth);
    var secondsDuration = 0;

    dateList.forEach((x) => {
      secondsDuration += TimeCard.getWorkingSecondsByDate(timeCardsByYearMonth, x.id);
    });

    return secondsDuration;
  },
  getNightOvertimeSecondsByYearMonth: (timeCards, yearMonth, payload) => {
    if (!payload.working_start_at || !payload.working_end_at) {return 0;}

    var dateList = DateTime.makeDatesInMonth(yearMonth);
    var holidayList = TimeCard.getHolidayListByYearMonth(yearMonth, payload);
    var needWorkDateList = dateList.filter(x =>
      !holidayList.map(y => y.formattedDate).includes(x.formattedDate)
    );
    var timeCardsByYearMonth = TimeCard.getListByYearMonth(timeCards, yearMonth);
    var secondsDuration = 0;

    needWorkDateList.forEach((x) => {
      let atWork = TimeCard.getAtWorkByDate(timeCardsByYearMonth, x.id);
      let leavingWork = TimeCard.getLeavingWorkByDate(timeCardsByYearMonth, x.id);
      if (atWork && leavingWork) {
        let nightStartAt = DateTime.createByString(x.id, payload.working_night_start_at);
        let nightEndAt = DateTime.createByString(x.id, payload.working_night_end_at);
        let atWorkAt = new Date(atWork.actived_at);
        // let leavingWorkAt = new Date(leavingWork.actived_at);

        if (
          atWorkAt.getTime() >= nightStartAt.getTime() &&
          atWorkAt.getTime() <= nightEndAt.getTime()
        ) {
          secondsDuration += DateTime.secondsDuration(atWorkAt, nightEndAt);
        }
      }
    });

    return secondsDuration;
  },
  getOvertimeSecondsByYearMonth: (timeCards, yearMonth, payload) => {
    if (!payload.working_start_at || !payload.working_end_at) {return 0;}

    var dateList = DateTime.makeDatesInMonth(yearMonth);
    var holidayList = TimeCard.getHolidayListByYearMonth(yearMonth, payload);
    var needWorkDateList = dateList.filter(x =>
      !holidayList.map(y => y.formattedDate).includes(x.formattedDate)
    );
    var timeCardsByYearMonth = TimeCard.getListByYearMonth(timeCards, yearMonth);
    var secondsDuration = 0;
    var nightOvertimeSeconds = TimeCard.getNightOvertimeSecondsByYearMonth(timeCards, yearMonth, payload);

    needWorkDateList.forEach((x) => {
      let atWork = TimeCard.getAtWorkByDate(timeCardsByYearMonth, x.id);
      let leavingWork = TimeCard.getLeavingWorkByDate(timeCardsByYearMonth, x.id);
      if (atWork && leavingWork) {
        let needStartAt = DateTime.createByString(x.id, payload.working_start_at);
        let needEndAt = DateTime.createByString(x.id, payload.working_end_at);
        let atWorkAt = new Date(atWork.actived_at);
        let leavingWorkAt = new Date(leavingWork.actived_at);
        let startOvertimeSeconds = DateTime.secondsDuration(atWorkAt, needStartAt);
        let endOvertimeSeconds = DateTime.secondsDuration(needEndAt, leavingWorkAt);
        if (startOvertimeSeconds > 0) {
          secondsDuration += startOvertimeSeconds;
        }
        if (endOvertimeSeconds > 0) {
          secondsDuration += endOvertimeSeconds;
        }
      }
    });

    return secondsDuration - nightOvertimeSeconds;
  },
  getNightHolidayWokingSecondsByYearMonth: (timeCards, yearMonth, payload) => {
    var timeCardsByYearMonth = TimeCard.getListByYearMonth(timeCards, yearMonth);
    var dateList = DateTime.makeDatesInMonth(yearMonth);
    var holidayList = TimeCard.getHolidayListByYearMonth(yearMonth, payload);
    var holidayDateList = dateList.filter(x =>
      holidayList.map(y => y.formattedDate).includes(x.formattedDate)
    );
    var secondsDuration = 0;

    holidayDateList.forEach((x) => {
      let atWork = TimeCard.getAtWorkByDate(timeCardsByYearMonth, x.id);
      let leavingWork = TimeCard.getLeavingWorkByDate(timeCardsByYearMonth, x.id);
      if (atWork && leavingWork) {
        let nightStartAt = DateTime.createByString(x.id, payload.working_night_start_at);
        let nightEndAt = DateTime.createByString(x.id, payload.working_night_end_at);
        let atWorkAt = new Date(atWork.actived_at);
        // let leavingWorkAt = new Date(leavingWork.actived_at);

        if (
          atWorkAt.getTime() >= nightStartAt.getTime() &&
          atWorkAt.getTime() <= nightEndAt.getTime()
        ) {
          secondsDuration += DateTime.secondsDuration(atWorkAt, nightEndAt);
        }
      }
    });

    return secondsDuration;
  },
  getHolidayWokingSecondsByYearMonth: (timeCards, yearMonth, payload) => {
    var timeCardsByYearMonth = TimeCard.getListByYearMonth(timeCards, yearMonth);
    var dateList = DateTime.makeDatesInMonth(yearMonth);
    var holidayList = TimeCard.getHolidayListByYearMonth(yearMonth, payload);
    var holidayDateList = dateList.filter(x =>
      holidayList.map(y => y.formattedDate).includes(x.formattedDate)
    );
    var secondsDuration = 0;
    var nightHolidayWorkingSeconds = TimeCard.getNightHolidayWokingSecondsByYearMonth(timeCards, yearMonth, payload);

    holidayDateList.forEach((x) => {
      secondsDuration += TimeCard.getWorkingSecondsByDate(timeCardsByYearMonth, x.id);
    });

    return secondsDuration - nightHolidayWorkingSeconds;
  },

  getNigthOvertimeAllowanceByYearMonth: (timeCards, yearMonth, payload) => {
    var seconds = TimeCard.getNightOvertimeSecondsByYearMonth(timeCards, yearMonth, payload);
    return (seconds / 3600) * payload.working_night_overtime_unit_price;
  },
  getOvertimeAllowanceByYearMonth: (timeCards, yearMonth, payload) => {
    var seconds = TimeCard.getOvertimeSecondsByYearMonth(timeCards, yearMonth, payload);
    return (seconds / 3600) * payload.working_overtime_unit_price;
  },
  getNightHolidayWokingAllowanceByYearMonth: (timeCards, yearMonth, payload) => {
    var seconds = TimeCard.getNightHolidayWokingSecondsByYearMonth(timeCards, yearMonth, payload);
    return (seconds / 3600) * payload.working_night_holiday_unit_price;
  },
  getHolidayWokingAllowanceByYearMonth: (timeCards, yearMonth, payload) => {
    var seconds = TimeCard.getHolidayWokingSecondsByYearMonth(timeCards, yearMonth, payload);
    return (seconds / 3600) * payload.working_holiday_unit_price;
  },

  initialize: (timeCards, payload) => {
    return {
      getListByDate: (date) => {
        return TimeCard.getListByDate(timeCards, date);
      },
      getAtWorkByDate: (date) => {
        return TimeCard.getAtWorkByDate(timeCards, date);
      },
      getLeavingWorkByDate: (date) => {
        return TimeCard.getLeavingWorkByDate(timeCards, date);
      },
      getTakingABreakListByDate: (date) => {
        return TimeCard.getTakingABreakListByDate(timeCards, date);
      },
      getGettingBackListByDate: (date) => {
        return TimeCard.getGettingBackListByDate(timeCards, date);
      },
      getBreakingSecondsByDate: (date) => {
        return TimeCard.getBreakingSecondsByDate(timeCards, date);
      },
      getWorkingSecondsByDate: (date) => {
        return TimeCard.getWorkingSecondsByDate(timeCards, date);
      },
      isNormalByDate: (date) => {
        return TimeCard.isNormalByDate(timeCards, date);
      },

      getListByYearMonth: (yearMonth) => {
        return TimeCard.getListByYearMonth(timeCards, yearMonth);
      },
      getHolidayListByYearMonth: (yearMonth) => {
        return TimeCard.getHolidayListByYearMonth(yearMonth, payload);
      },
      getAtWorkCountByYearMonth: (yearMonth) => {
        return TimeCard.getAtWorkCountByYearMonth(timeCards, yearMonth);
      },
      getAbsenceCountByYearMonth: (yearMonth) => {
        return TimeCard.getAbsenceCountByYearMonth(timeCards, yearMonth, payload);
      },
      getBeLateCountByYearMonth: (yearMonth) => {
        return TimeCard.getBeLateCountByYearMonth(timeCards, yearMonth, payload);
      },
      getLeavingEarlyCountByYearMonth: (yearMonth) => {
        return TimeCard.getLeavingEarlyCountByYearMonth(timeCards, yearMonth, payload);
      },
      getHolidayAtWorkCountByYearMonth: (yearMonth) => {
        return TimeCard.getHolidayAtWorkCountByYearMonth(timeCards, yearMonth, payload);
      },
      getWorkingSecondsByYearMonth: (yearMonth) => {
        return TimeCard.getWorkingSecondsByYearMonth(timeCards, yearMonth);
      },
      getOvertimeSecondsByYearMonth: (yearMonth) => {
        return TimeCard.getOvertimeSecondsByYearMonth(timeCards, yearMonth, payload);
      },
      getNightOvertimeSecondsByYearMonth: (yearMonth) => {
        return TimeCard.getNightOvertimeSecondsByYearMonth(timeCards, yearMonth, payload);
      },
      getNightHolidayWokingSecondsByYearMonth: (yearMonth) => {
        return TimeCard.getNightHolidayWokingSecondsByYearMonth(timeCards, yearMonth, payload);
      },
      getHolidayWokingSecondsByYearMonth: (yearMonth) => {
        return TimeCard.getHolidayWokingSecondsByYearMonth(timeCards, yearMonth, payload);
      },

      getNigthOvertimeAllowanceByYearMonth: (yearMonth) => {
        return TimeCard.getNigthOvertimeAllowanceByYearMonth(timeCards, yearMonth, payload);
      },
      getOvertimeAllowanceByYearMonth: (yearMonth) => {
        return TimeCard.getOvertimeAllowanceByYearMonth(timeCards, yearMonth, payload);
      },
      getNightHolidayWokingAllowanceByYearMonth: (yearMonth) => {
        return TimeCard.getNightHolidayWokingAllowanceByYearMonth(timeCards, yearMonth, payload);
      },
      getHolidayWokingAllowanceByYearMonth: (yearMonth) => {
        return TimeCard.getHolidayWokingAllowanceByYearMonth(timeCards, yearMonth, payload);
      },
    };
  },
};

export const Navigation = {
  replaceParams: (routeCode, params, queryStringParameters) => {
    var result = '';
    var route = General.getConstant(Constants.Navigation.Route, routeCode).this;
    if (!route.code) { return result};

    result = route.path;

    if (params) {
      Object.keys(params).forEach((key) => {
        let item = params[key];

        result = result.replace(`:${key}`, item);
      });
    }

    if (queryStringParameters) {
      result += '?';
      Object.keys(queryStringParameters).forEach((key) => {
        let item = queryStringParameters[key];

        result += `${key}=${item}`
      });
    }

    return result;
  },
  getCurrentRoute: () => {
    const routes = General.getConstant(Constants.Navigation.Route).list;
    const curentPathSlices = window.location.pathname.split('/');

    var route = routes.find(x => {
      var routePathSlices = x.path.split('/');
      var routePathName = "";

      routePathSlices.forEach((y, yIndex) => {
        if (y.includes(':') && curentPathSlices[yIndex]) {
          routePathName += curentPathSlices[yIndex];
        }
        else {
          routePathName += y;
        }

        if (yIndex !== routePathSlices.length - 1) {
          routePathName += "/";
        }
      });

      var isMatched =
        routePathName === window.location.pathname ||
        routePathName + "/" === window.location.pathname;
      return x.path === '/' && window.location.pathname !== '/' ?
        false :
        isMatched;
    });

    return route;
  },
  getRouteBackPath: (routeCode) => {
    const route = General.getConstant(Constants.Navigation.Route, routeCode).this;
    var backPath = "";

    if (!route) return backPath;

    const curentPathSlices = window.location.pathname.split('/');
    var routePathSlices = route.path.split('/');
    var routeBackPathSlices = route.backPath.split('/');
    var params = {};

    routePathSlices.forEach((x, xIndex) => {
      if (x.includes(':') && curentPathSlices[xIndex]) {
        params[x] = curentPathSlices[xIndex];
      }
    });
    routeBackPathSlices.forEach((x) => {
      if (x.includes(':') && params.hasOwnProperty(x)) {
        backPath += params[x];
      }
      else {
        backPath += x;
      }

      if (x !== routeBackPathSlices.length - 1) {
        backPath += "/";
      }
    });

    return backPath;
  },
  makeRoute: (routeCode, params, queryStringParameters) => {
    var route = General.getConstant(Constants.Navigation.Route, routeCode).this;
    if (route) {
      route.label =  i18n.t(route.name);
      route.locationPath = Navigation.replaceParams(route.code, params, queryStringParameters);
    }

    return route;
  },
  makeRoutes: (routeCodes, params, queryStringParameters) => {
    var routes = General.getConstant(Constants.Navigation.Route).list.filter(x =>
      routeCodes.includes(x.code)
    ).map(x => {
      return Navigation.makeRoute(x.code, params, queryStringParameters);
    });

    return routes;
  }
};

export const ProjectHistory = {
  makeStatusLabel: (item, useBeforeChange) => {
    var historyItem = useBeforeChange ? item.before_change : item;
    var type = General.getConstant(Constants.ProjectHistory.Type, historyItem.type_code).this;
    var collectionLabel = historyItem.collection_label;
    var collectionAgainCount = historyItem.collection_again_count;
    var label = '';
    if (type.code === Constants.ProjectHistory.Type.FlyerBeforeCollection.code) {
      label = collectionLabel;
    }
    else if (
      type.code === Constants.ProjectHistory.Type.FlyerAbsence.code ||
      type.code === Constants.ProjectHistory.Type.FlyerConsideration.code
    ) {
      label = `${i18n.t(type.name)} ${collectionAgainCount}`;
    }
    else {
      label = i18n.t(type.name);
    }
    return label;
  },
  aggregateItems: (items) => {
    items.map(x => {
      x.aggregate_type_label =
        `${ProjectHistory.makeStatusLabel(x, true)}_${ProjectHistory.makeStatusLabel(x, false)}`;
      return x;
    })
    var aggregate = Arrayer.groupBy(items, 'aggregate_type_label');
    var records = [];
    var count = 0;
    Object.keys(aggregate).forEach((key) => {
      let value = aggregate[key];
      let firstX = value[0];
      records.push({
        label: ProjectHistory.makeStatusLabel(firstX, false),
        before_change_label: ProjectHistory.makeStatusLabel(firstX, true),
        count: value.length,
      });
      count += value.length;
    });

    return {
      items: records,
      count: count,
    }
  }
};

export const ChatRoom = {
  makeItem: (item) => {
    if (!item) return item;

    item.unread_message_count = item.unread_messages?.length;
    const authUser = Auths.getUser();

    const processGroupItem = (item, authUserId) => {
      const setTypeSpecificInfo = (name, cover_image_path) => {
        item.name = name;
        item.cover_image_path = cover_image_path || item.cover_image_path;
      };
      const me = item.participants?.find(x => x.id === authUserId);
      const opponent = item.participants?.find(x => x.id !== authUserId);
      switch (item.type_code) {
        case Constants.Group.Type.Private.code:
          me && setTypeSpecificInfo(me.full_name, me.avatar_image_path);
          break;
        case Constants.Group.Type.Individual.code:
          opponent && setTypeSpecificInfo(opponent.full_name, opponent.avatar_image_path);
          break;
        case Constants.Group.Type.Branch.code:
        case Constants.Group.Type.Social.code:
          setTypeSpecificInfo(item.group_name);
          break;
        default:
          break;
      }
    };
    processGroupItem(item, authUser.actived_profile_id);

    item.chat_messages?.forEach((message) => {
      const { profile_id, already_read_profile_ids, profile } = message;
      const isOwner = authUser.actived_profile_id === profile_id;
      const readOpponentIds = already_read_profile_ids.filter((id) => id !== authUser.actived_profile_id);

      Object.assign(message, {
        is_owner: isOwner,
        is_opponent_already_read: isOwner && readOpponentIds.length,
        side: isOwner ? 'right' : 'left',
        read_opponent_count: readOpponentIds.length,
        avatar_image_path: profile?.avatar_image_path,
      });
    });

    // online
    // var onlineOpponents = item.participants.filter((x) => x.is_online && x.id !== authUser.actived_profile_id);
    // item.is_online = onlineOpponents.length > 0 ? true : false;

    // type
    // if (item.type_code === Constants.ChatRoom.Type.Project.code) {
    //   item.name = item.project?.customer_name;
    //   if (item.project?.image_paths[0]) {
    //     item.cover_image_path = item.project?.image_paths[0];
    //   }
    // }
    return item;
  },
  makeItems: (items) => {
    if (!items) return items;

    const processedItems = Array.isArray(items)
      ? items.map(x => ChatRoom.makeItem(x)).sort((a, b) => b.changed_at - a.changed_at)
      : ChatRoom.makeItem(items);
    return processedItems;
  },
};

export const Group = {
  makeItem: (item) => {
    if (!item) return item;

    const authUser = Auths.getUser();

    // type
    if (item.type_code === Constants.Group.Type.Private.code) {
      var me = item.participants?.find(x => x.id === authUser.actived_profile_id);
      if (me) {
        item.group_name = me.full_name;
        item.cover_image_path = me.avatar_image_path;
      }
    }
    if (item.type_code === Constants.Group.Type.Individual.code) {
      var opponent = item.participants?.find(x => x.id !== authUser.actived_profile_id)
      if (opponent) {
        item.group_name = opponent.full_name;
        item.cover_image_path = opponent.avatar_image_path
      }
    }

    return item;
  },
  makeItems: (items) => {
    if (!items) return items;

    items = items.map(x => Group.makeItem(x));

    return items;
  },
}

export const BreakdownEstimation = {
  groupByTemplateName: (items) => {
    var grouped = Arrayer.groupBy(items, 'template_name');
    var newItems = []
    Object.keys(grouped).forEach((key) => {
      let value = grouped[key];
      newItems.push({
        ...value[0],
        breakdown_estimations: value,
      });
    });
    return newItems;
  },
}

export const PartsOrderRequest = {
  groupByTemplateName: (items) => {
    var grouped = Arrayer.groupBy(items, 'template_name');
    var newItems = []
    Object.keys(grouped).forEach((key) => {
      let value = grouped[key];
      newItems.push({
        ...value[0],
        parts_order_requests: value,
      });
    });
    return newItems;
  },
}

export const Project = {
  makeItem: (item) => {
    if (!item) return item;

    const authUser = Auths.getUser();

    // approval_profile_id approval_profile
    if (item.group) {
      var approvalPairs = item.group.approval_pairs || [];
      var participants = item.group.participants;
      var currentApprovalPair = approvalPairs.find(x => x.profile_id === authUser.actived_profile_id);
      item.approval_profile_id = currentApprovalPair ? currentApprovalPair.approval_profile_id : authUser.actived_profile_id;
      item.approval_profile = participants.find(x => x.id === item.approval_profile_id);
    }

    return item;
  },
  makeItems: (items) => {
    if (!items) return items;

    items = items.map(x => Group.makeItem(x));

    return items;
  },
}

export const Material = {
  getCoverEstimationType: (estimationDisplay) => {
    const types = General.getConstant(Constants.CoverEstimation.Type).list;
    const list = estimationDisplay ? types.filter(x => x.estimationDisplay) : types;
    return {
      list: list.map(x => {
        return {
          ...x,
          label: estimationDisplay ? x.estimatioLabel : x.label,
        }
      }),
      inputList: list.map(x => {
        return {
          name: x.name,
          value: x.code,
          label: estimationDisplay ? x.estimatioLabel : x.label,
        }
      }),
    }
  },
}
