
export const General = {
  Env: {
    primaryColor: "#347BFF",
    defaultColor: "#e0e0e0",
  },
  Locale: {
    ja: {
      code: "ja",
      name: "ja",
      label: "日本",
      flagClassName: "fi fi-jp fis",
      phoneNumberPrefix: "+81"
    },
    us: {
      code: "us",
      name: "us",
      label: "US",
      flagClassName: "fi fi-us fis",
      phoneNumberPrefix: "+1"
    },
    vn: {
      code: "vn",
      name: "vn",
      label: "Vietnam",
      flagClassName: "fi fi-vn fis",
      phoneNumberPrefix: "+84"
    },
  },
  Crud: {
    Create: {
      code: "create",
      name: "create",
      label: "Create",
    },
    Read: {
      code: "read",
      name: "read",
      label: "Read",
    },
    Update: {
      code: "update",
      name: "update",
      label: "Update",
    },
    Delete: {
      code: "delete",
      name: "delete",
      label: "Delete",
    },
  },
};
export const Config = {
  WeekBeginning: {
    Monday: {
      code: "monday",
      name: "monday",
      label: "Monday",
      moment: 1,
    },
    Sunday: {
      code: "sunday",
      name: "sunday",
      label: "Sunday",
      moment: 0,
    },
  },
  TimeDisplay: {
    _12h: {
      code: "_12h",
      name: "_12h",
      label: "12h",
    },
    _24h: {
      code: "_24h",
      name: "_24h",
      label: "24h",
    },
  },
  WeekOfMonth: {
    _0: { code: 0, name: 'week_of_month_0', label: 'week_of_month_0' },
    _1: { code: 1, name: 'week_of_month_1', label: 'week_of_month_1' },
    _2: { code: 2, name: 'week_of_month_2', label: 'week_of_month_2' },
    _3: { code: 3, name: 'week_of_month_3', label: 'week_of_month_3' },
    _4: { code: 4, name: 'week_of_month_4', label: 'week_of_month_4' },
  },
  DayOfMonth: {
    _0: { code: 0, name: 'day_of_month_0', label: 'day_of_month_0' },
    _1: { code: 1, name: 'day_of_month_1', label: 'day_of_month_1' },
    _2: { code: 2, name: 'day_of_month_2', label: 'day_of_month_2' },
    _3: { code: 3, name: 'day_of_month_3', label: 'day_of_month_3' },
    _4: { code: 4, name: 'day_of_month_4', label: 'day_of_month_4' },
    _5: { code: 5, name: 'day_of_month_5', label: 'day_of_month_5' },
    _6: { code: 6, name: 'day_of_month_6', label: 'day_of_month_6' },
    _7: { code: 7, name: 'day_of_month_7', label: 'day_of_month_7' },
    _8: { code: 8, name: 'day_of_month_8', label: 'day_of_month_8' },
    _9: { code: 9, name: 'day_of_month_9', label: 'day_of_month_9' },
    _10: { code: 10, name: 'day_of_month_10', label: 'day_of_month_10' },
    _11: { code: 11, name: 'day_of_month_11', label: 'day_of_month_11' },
    _12: { code: 12, name: 'day_of_month_12', label: 'day_of_month_12' },
    _13: { code: 13, name: 'day_of_month_13', label: 'day_of_month_13' },
    _14: { code: 14, name: 'day_of_month_14', label: 'day_of_month_14' },
    _15: { code: 15, name: 'day_of_month_15', label: 'day_of_month_15' },
    _16: { code: 16, name: 'day_of_month_16', label: 'day_of_month_16' },
    _17: { code: 17, name: 'day_of_month_17', label: 'day_of_month_17' },
    _18: { code: 18, name: 'day_of_month_18', label: 'day_of_month_18' },
    _19: { code: 19, name: 'day_of_month_19', label: 'day_of_month_19' },
    _20: { code: 20, name: 'day_of_month_20', label: 'day_of_month_20' },
    _21: { code: 21, name: 'day_of_month_21', label: 'day_of_month_21' },
    _22: { code: 22, name: 'day_of_month_22', label: 'day_of_month_22' },
    _23: { code: 23, name: 'day_of_month_23', label: 'day_of_month_23' },
    _24: { code: 24, name: 'day_of_month_24', label: 'day_of_month_24' },
    _25: { code: 25, name: 'day_of_month_25', label: 'day_of_month_25' },
    _26: { code: 26, name: 'day_of_month_26', label: 'day_of_month_26' },
    _27: { code: 27, name: 'day_of_month_27', label: 'day_of_month_27' },
    _29: { code: 29, name: 'day_of_month_29', label: 'day_of_month_29' },
    _30: { code: 30, name: 'day_of_month_30', label: 'day_of_month_30' },
  }
};

// data
export const User = {
  MaxProfile: 4,
  Type: {
    User: {
      code: "user",
      name: "user",
      label: "User",
    },
    Bot: {
      code: "bot",
      name: "bot",
      label: "Bot",
    },
    System: {
      code: "system",
      name: "system",
      label: "System",
    },
  },
};
export const Profile = {
  Role: {
    User: {
      code: "user",
      name: "user",
      label: "User",
    },
    Staff: {
      code: "staff",
      name: "staff",
      label: "Staff",
    },
    Admin: {
      code: "admin",
      name: "admin",
      label: "Admin",
    },
  },
};
export const File = {
  Status: {
    New: {
      code: "new",
      name: "new",
      label: "New",
    },
    UnderTranscription: {
      code: "under_transcription",
      name: "under_transcription",
      label: "Under Transcription",
    },
    TranscriptionFailure: {
      code: "transcription_failure",
      name: "transcription_failure",
      label: "Transcription Failure",
    },
    TranscriptionCompleted: {
      code: "transcription_completed",
      name: "transcription_completed",
      label: "Transcription Completed",
    },
    UnderAnalysis: {
      code: "under_analysis",
      name: "under_analysis",
      label: "Under Analysis",
    },
    AnalysisFailure: {
      code: "analysis_failure",
      name: "analysis_failure",
      label: "Analysis Failure",
    },
    AnalysisCompleted: {
      code: "analysis_completed",
      name: "analysis_completed",
      label: "Analysis Completed",
    },
  },
  Type: {
    Audio: {
      code: "audio",
      name: "audio",
      label: "Audio",
      path: "audio/",
    },
    ProfileAvatar: {
      code: "profile_avatar",
      name: "profile_avatar",
      label: "Profile Avatar",
      path: "profile/avatar/",
    },
    ChatRoomCover: {
      code: "chat_room_cover",
      name: "chat_room_cover",
      label: "chat_room_cover",
      path: "chat-room/cover/",
    },
    ChatRoomMessageMedia: {
      code: "chat_room_message_media",
      name: "chat_room_message_media",
      label: "chat_room_message_media",
      path: "chat-room/message-media/",
    },
    ChatRoomMessageFile: {
      code: "chat_room_message_file",
      name: "chat_room_message_file",
      label: "chat_room_message_file",
      path: "chat-room/message-file/",
    },
    Event: {
      code: "event",
      name: "event",
      label: "event",
      path: "event/",
    },
    Album: {
      code: "album",
      name: "album",
      label: "album",
      path: "album/",
    },
    Note: {
      code: "note",
      name: "note",
      label: "note",
      path: "note/",
    },
    Expense: {
      code: "expense",
      name: "expense",
      label: "expense",
      path: "expense/",
    },
    GroupCover: {
      code: "group_cover",
      name: "group_cover",
      label: "Group Cover",
      path: "group/cover/",
    },
    ServiceCover: {
      code: "service_cover",
      name: "service_cover",
      label: "Service Cover",
      path: "service/cover/",
    },
    GroupCompanySignatureStamp: {
      code: "group_company_signature_stamp",
      name: "group_company_signature_stamp",
      label: "Group Company Signature Stamp",
      path: "group/company-signature-stamp/",
    },
    ProjectImage: {
      code: "project_image",
      name: "project_image",
      label: "Project Image",
      path: "project/image/",
    },
    ProjectSinature: {
      code: "project_sinature",
      name: "project_sinature",
      label: "Project Sinature",
      path: "project/sinature/",
    },
    ProjectStamp: {
      code: "project_stamp",
      name: "project_stamp",
      label: "Project Stamp",
      path: "project/stamp/",
    },
    ProjectLocalResearch: {
      code: "project_local_research",
      name: "project_local_research",
      label: "Project Local Research",
      path: "project/local-research/",
    },
    ProjectRepairPart: {
      code: "project_repair_part",
      name: "project_repair_part",
      label: "Project Repair Part",
      path: "project/repair-part/",
    },
    ProjectDrawing: {
      code: "project_drawing",
      name: "project_drawing",
      label: "Project Drawing",
      path: "project/local-drawing/",
    },
    ProjectCertificate: {
      code: "project_certificate",
      name: "project_certificate",
      label: "Project Certificate",
      path: "project/project-certificate/",
    },
    ProjectCompletion: {
      code: "project_completion",
      name: "project_completion",
      label: "Project Completion",
      path: "project/completion/",
    },
  },
  Sentiment: {
    Neutral: {
      code: "NEUTRAL",
      name: "neutral",
      label: "Neutral",
      color: "#009944",
      className: "sentiment-neutral",
    },
    Positive: {
      code: "POSITIVE",
      name: "positive",
      label: "Positive",
      color: "#f39800",
      className: "sentiment-positive",
    },
    Negative: {
      code: "NEGATIVE",
      name: "negative",
      label: "Negative",
      color: "#999999",
      className: "sentiment-negative",
    },
    Interruption: {
      code: "Interruption",
      name: "interruption",
      label: "Interruption",
      color: "#100964",
      className: "sentiment-interruption",
    },
    Issue: {
      code: "Issue",
      name: "issue",
      label: "Issue",
      color: "#E5004F",
      className: "sentiment-issue",
    },
  },
  FileType: {
    Image: {
      code: "image",
      name: "image",
      label: "Image",
      extensions: [
        'jpg', 'jpeg', 'jpe', 'jif', 'jfif', 'jfi', 'png', 'gif', 'webp',
        'tiff', 'tif', 'psd', 'raw', 'arw', 'cr2', ' nrw', 'k25', 'bmp',
        'dib', 'heif', 'heic', 'ind', 'indd', 'indt', 'jp2', 'j2k', 'jpf',
        'jpx', 'jpm', 'mj2', 'svg', 'svgz', 'eps'
      ],
    },
    Audio: {
      code: "audio",
      name: "audio",
      label: "Audio",
      extensions: [
        'm4a', 'flac', 'mp3', 'wav', 'wma', 'aac'
      ],
    },
    Video: {
      code: "video",
      name: "video",
      label: "Video",
      extensions: [
        'mp4', 'wov', 'wmv', 'avi', 'avchd', ' flv', 'f4v', 'swf', 'mkv', ' webm', 'mpeg',
      ],
    },
  }
};
export const Group = {
  Type: {
    Private: {
      code: "private",
      name: "private",
      label: "Private",
    },
    Individual: {
      code: "individual",
      name: "individual",
      label: "Individual",
    },
    Social: {
      code: "social",
      name: "social",
      label: "Social",
    },
    Branch: {
      code: "branch",
      name: "branch",
      label: "Branch",
    },
    Global: {
      code: "global",
      name: "global",
      label: "Global",
    },
    Project: {
      code: "project",
    },
  },
  Role: {
    Guest: {
      code: "guest",
      name: "guest",
      label: "Guest",
    },
    InvitationValid: {
      code: "invitation_valid",
      name: "invitation_valid",
      label: "Invitation Valid",
    },
    Participant: {
      code: "participant",
      name: "participant",
      label: "Participant",
    },
    Owner: {
      code: "owner",
      name: "owner",
      label: "Owner",
    },
  }
};
export const Event = {
  Type: {
    Calendar: {
      code: "calendar",
      name: "calendar",
      label: "Calendar",
    },
    Todo: {
      code: "todo",
      name: "todo",
      label: "Todo",
    },
    ProjectLocalResearch: {
      code: "project_local_research",
      name: "project_local_research",
      label: "Project",
      notificationTitle: "notification_title_project_local_research",
      notificationContent: "notification_content_project_local_research",
      anchorName: "local_research_at",
      projectFieldName: "local_research_events",
      allDay: false,
    },
    ProjectCustomerInsuranceApplicationDesire: {
      code: "project_customer_insurance_application_desire",
      name: "project_customer_insurance_application_desire",
      label: "Project",
      notificationTitle: "notification_title_project_customer_insurance_application_desire",
      notificationContent: "notification_content_project_customer_insurance_application_desire",
      anchorName: "customer_insurance_application_desire_at",
      projectFieldName: "customer_insurance_application_desire_events",
      allDay: false,
    },
    ProjectInsuranceExaminationResult: {
      code: "project_insurance_examination_result",
      name: "project_insurance_examination_result",
      label: "Project",
      notificationTitle: "notification_title_project_insurance_examination_result",
      notificationContent: "notification_content_project_insurance_examination_result",
      anchorName: "insurance_examination_result_at",
      projectFieldName: "insurance_examination_result_events",
      allDay: false,
    },
    ProjectCertification: {
      code: "project_certification",
      name: "project_certification",
      label: "Project",
      notificationTitle: "notification_title_project_certification",
      notificationContent: "notification_content_project_certification",
      anchorName: "certification_at",
      projectFieldName: "certification_events",
      allDay: false,
    },
    ProjectPaymentPeriod: {
      code: "project_payment_period",
      name: "project_payment_period",
      label: "Project",
      notificationTitle: "notification_title_project_payment_period",
      notificationContent: "notification_content_project_payment_period",
      anchorName: "payment_period_at",
      projectFieldName: "payment_period_events",
      allDay: true,
    },
    ProjectRepairWork: {
      code: "repair_work",
      name: "repair_work",
      label: '雨樋修繕載せ替え工事日',
      notificationTitle: "notification_title_project_repair_work",
      notificationContent: "notification_content_project_repair_work",
      anchorName: "",
      projectFieldName: "repair_work_events",
      allDay: false,
    },
    ProjectExchangeWork: {
      code: "exchange_work",
      name: "exchange_work",
      label: '雨樋修繕打ち換え工事日',
      notificationTitle: "notification_title_project_exchange_work",
      notificationContent: "notification_content_project_exchange_work",
      anchorName: "",
      projectFieldName: "exchange_work_events",
      allDay: false,
    },
    ProjectScaffoldInstallation: {
      code: "scaffold_installation",
      name: "scaffold_installation",
      label: '足場設置工事日',
      notificationTitle: "notification_title_project_scaffold_installation",
      notificationContent: "notification_content_project_scaffold_installation",
      anchorName: "",
      projectFieldName: "scaffold_installation_events",
      allDay: false,
    },
    ProjectPaintingWork: {
      code: "painting_work",
      name: "painting_work",
      label: '塗装工事日',
      notificationTitle: "notification_title_project_painting_work",
      notificationContent: "notification_content_project_painting_work",
      anchorName: "",
      projectFieldName: "painting_work_events",
      allDay: false,
    },
    ProjectSheetMetalWork: {
      code: "sheet_metal_work",
      name: "sheet_metal_work",
      label: '板金工事日',
      notificationTitle: "notification_title_project_sheet_metal_work",
      notificationContent: "notification_content_project_sheet_metal_work",
      anchorName: "",
      projectFieldName: "sheet_metal_work_events",
      allDay: false,
    },
    ProjectScaffoldDismantling: {
      code: "scaffold_dismantling",
      name: "scaffold_dismantling",
      label: '足場解体工事日',
      notificationTitle: "notification_title_project_scaffold_dismantling",
      notificationContent: "notification_content_project_scaffold_dismantling",
      anchorName: "",
      projectFieldName: "scaffold_dismantling_events",
      allDay: false,
    },

  },
  Status: {
    New: {
      code: "new",
      name: "new",
      label: "New",
    },
    Completed: {
      code: "completed",
      name: "completed",
      label: "Completed",
    },
  },
  Repeat: {
    NotRepeating: {
      code: "not_repeating",
      name: "not_repeating",
      label: "Not Repeating",
    },
    Everyday: {
      code: "everyday",
      name: "everyday",
      label: "Everyday",
    },
    EachWeekXDayOfWeek: {
      code: "each_week_x_day_of_week",
      name: "each_week_x_day_of_week",
      label: "Each week X DayOfWeek",
    },
    Weekdays: {
      code: "weekdays",
      name: "weekdays",
      label: "Weekdays",
    },
    EveryYear: {
      code: "every_year",
      name: "every_year",
      label: "Every Year",
    },
  },
  DeleteType: {
    DeleteAll: {
      code: "delete_all_event",
      name: "delete_all_event",
      label: "Delete All Event",
    },
    DeleteThisEvent: {
      code: "delete_this_event",
      name: "delete_this_event",
      label: "Delete This Event",
    },
    DeleteFromThisEvent: {
      code: "delete_from_this_event",
      name: "delete_from_this_event",
      label: "Delete From This Event",
    },
  },
  EditType: {
    EditAll: {
      code: "edit_all_event",
      name: "edit_all_event",
      label: "Edit All Event",
    },
    EditThisEvent: {
      code: "edit_this_event",
      name: "edit_this_event",
      label: "Edit This Event",
    }
  },
  NotificationUnit: {
    nDaysBefore: {
      code: "n_days_before",
      name: "n_days_before",
      label: "n Days Before",
      applyForAllDay: true,
    },
    nWeeksBefore: {
      code: "n_weeks_before",
      name: "n_weeks_before",
      label: "n Weeks Before",
      applyForAllDay: true,
    },
    nMinutesBefore: {
      code: "n_minutes_before",
      name: "n_minutes_before",
      label: "n Minutes Before",
      applyForAllDay: false,
    },
    nHoursBefore: {
      code: "n_hours_before",
      name: "n_hours_before",
      label: "n Hours Before",
      applyForAllDay: false,
    },
  },
};
export const Todo = {
  State: {
    List: {
      code: "list",
      name: "list",
      label: "List",
    },
    Request: {
      code: "request",
      name: "request",
      label: "Request",
    },
    Acceptance: {
      code: "acceptance",
      name: "acceptance",
      label: "Acceptance",
    },
    Completion: {
      code: "completion",
      name: "completion",
      label: "Completion",
    },
  },
};
export const Project = {
  GeneralStatus: {
    FlyerCollectionDistribution: {
      code: "flyer_collection_distribution",
      name: "flyer_collection_distribution",
      label: "Flyer Collection Distribution",
      statusCodes: [
        'flyer_distribution',
        'flyer_collection',
      ],
      statusRange: [0, 1],
      iconColor: "#009933",
      iconOpacity: 1,
      iconLabel: 'チ',
    },
    OnsiteResearch: {
      code: "onsite_research",
      name: "onsite_research",
      label: "Onsite Research",
      statusCodes: [
        'before_onsite_research',
        'onsite_research',
      ],
      statusRange: [2, 3],
      iconColor: "#009933",
      iconOpacity: 1,
      iconLabel: '現',
    },
    DrawingAndEstimation: {
      code: "drawing_and_estimation",
      name: "drawing_and_estimation",
      label: "Drawing And Estimation",
      statusCodes: [
        'drawing_and_estimation_create',
        'drawing_and_estimation_approval',
      ],
      statusRange: [4, 5],
      iconColor: "#009933",
      iconOpacity: 1,
      iconLabel: '図',
    },
    InsuranceApplication: {
      code: "insurance_application",
      name: "insurance_application",
      label: "InsuranceApplication",
      statusCodes: [
        'insurance_application_submission',
        'drawing_and_estimation_adjustment',
        'drawing_and_estimation_adjustment_approval',
        'contract_content_confirmation',
      ],
      statusRange: [6, 9],
      iconColor: "#009933",
      iconOpacity: 1,
      iconLabel: '保',
    },
    Construction: {
      code: "construction",
      name: "construction",
      label: "Construction",
      statusCodes: [
        'construction_adjustment',
        'construction'
      ],
      statusRange: [10, 11],
      iconColor: "#009933",
      iconOpacity: 1,
      iconLabel: '施',
    },
    CompletionReport: {
      code: "completion_report",
      name: "completion_report",
      label: "Completion Report",
      statusCodes: [
        'completion_report_create',
        'completion_report',
        'waiting_for_payment',
        'payment_received',
      ],
      statusRange: [12, 15],
      iconColor: "#009933",
      iconOpacity: 1,
      iconLabel: '完',
    },
  },
  Status: {
    FlyerDistribution: {
      code: "flyer_distribution",
      name: "flyer_distribution",
      label: "Flyer Distribution",
      description: "flyer_distribution_description",
    },
    FlyerCollection: {
      code: "flyer_collection",
      name: "flyer_collection",
      label: "Flyer Collection",
      description: "flyer_collection_description",
    },
    BeforeOnsiteResearch: {
      code: "before_onsite_research",
      name: "before_onsite_research",
      label: "Before Onsite Research",
      description: "before_onsite_research_description",
    },
    OnsiteResearch: {
      code: "onsite_research",
      name: "onsite_research_0",
      label: "Onside Research",
      description: "onsite_research_description",
    },
    DrawingAndEstimationCreate: {
      code: "drawing_and_estimation_create",
      name: "drawing_and_estimation_create",
      label: "Drawing And Estimation Create",
      description: "drawing_and_estimation_create_description",
    },
    DrawingAndEstimationApproval: {
      code: "drawing_and_estimation_approval",
      name: "drawing_and_estimation_approval",
      label: "Drawing And Estimation Approval",
      description: "drawing_and_estimation_approval_description",
    },
    InsuranceApplicationSubmission: {
      code: "insurance_application_submission",
      name: "insurance_application_submission",
      label: "Insurance Application Submission",
      description: "insurance_application_submission_description",
    },
    DrawingAndEstimationAdjustment: {
      code: "drawing_and_estimation_adjustment",
      name: "drawing_and_estimation_adjustment",
      label: "Drawing And Estimation Adjustment",
      description: "drawing_and_estimation_adjustment_description",
    },
    DrawingAndEstimationAdjustmentApproval: {
      code: "drawing_and_estimation_adjustment_approval",
      name: "drawing_and_estimation_adjustment_approval",
      label: "Drawing And Estimation Adjustment_approval",
      description: "drawing_and_estimation_adjustment_approval_description",
    },
    ContractContentConfirmation: {
      code: "contract_content_confirmation",
      name: "contract_content_confirmation",
      label: "Contract Content Confirmation",
      description: "contract_content_confirmation_description",
    },
    ConstructionAdjustment: {
      code: "construction_adjustment",
      name: "construction_adjustment",
      label: "Construction Adjustment",
      description: "construction_adjustment_description",
    },
    Construction: {
      code: "construction",
      name: "construction_0",
      label: "Construction",
      description: "construction_description",
    },
    CompletionReportCreate: {
      code: "completion_report_create",
      name: "completion_report_create",
      label: "Completion Report Create",
      description: "completion_report_create_description",
    },
    CompletionReport: {
      code: "completion_report",
      name: "completion_report_0",
      label: "Completion Report",
      description: "completion_report_description",
    },
    WaitingForPayment: {
      code: "waiting_for_payment",
      name: "waiting_for_payment",
      label: "Waiting For Payment",
      description: "waiting_for_payment_description",
    },
    PaymentReceived: {
      code: "payment_received",
      name: "payment_received",
      label: "Payment Received",
      description: "payment_received_description",
    },
  },
  FlyerStatus: {
    BeforeCollection: {
      code: "before_collection",
      name: "before_collection",
      label: "Before Collection",
      statusCode: "flyer_distribution",
      iconColor: "#6666ff",
      iconOpacity: 1,
      iconLabel: 'ア',
    },
    WishForRepair: {
      code: "wish_for_repair",
      name: "wish_for_repair",
      label: "Wish For Repair",
      statusCode: "flyer_collection",
      iconColor: "#009933",
      iconOpacity: 1,
      iconLabel: '望',
    },
    Consideration: {
      code: "consideration",
      name: "consideration",
      label: "consideration",
      statusCode: "flyer_collection",
      iconColor: "#e600ac",
      iconOpacity: 1,
      iconLabel: '検',
    },
    Absence: {
      code: "absence",
      name: "absence",
      label: "Absence",
      statusCode: "flyer_collection",
      iconColor: "#cccc00",
      iconOpacity: 1,
      iconLabel: '留',
    },
    Refusal: {
      code: "refusal",
      name: "refusal",
      label: "Refusal",
      statusCode: "flyer_collection",
      iconColor: "#737373",
      iconOpacity: 1,
      iconLabel: '断',
    },
    NoVisit: {
      code: "no_visit",
      name: "no_visit",
      label: "No visit",
      statusCode: "flyer_collection",
      iconColor: "#737373",
      iconOpacity: 1,
      iconLabel: "断",
    },
    NotApplicable: {
      code: "not_applicable",
      name: "not_applicable",
      label: "Not Applicable",
      statusCode: "flyer_collection",
      iconColor: "#a6a6a6",
      iconOpacity: 1,
      iconLabel: '外',
    },
    UnoccupiedHouse: {
      code: "unoccupied_house",
      name: "unoccupied_house",
      label: "Unoccupied House",
      statusCode: "flyer_collection",
      iconColor: "#999999",
      iconOpacity: 1,
      iconLabel: '空',
    },
    Others: {
      code: "others",
      name: "others",
      label: "Others",
      statusCode: "flyer_collection",
      iconColor: "#c2d6d6",
      iconOpacity: 1,
      iconLabel: '他',
    },
    DamageDetection: {
      code: "damage_detection",
      name: "damage_detection",
      label: "Damage Detection",
      statusCode: "flyer_distribution",
      iconColor: "#737373",
      iconOpacity: 1,
      iconLabel: '損',
    },
  },
  BusinessForm: {
    Collection: {
      code: "collection",
      name: "collection",
      label: "Collection",
    },
    Visit: {
      code: "visit",
      name: "visit",
      label: "Visit",
    },
    Response: {
      code: "response",
      name: "response",
      label: "Response",
    },
  },
  ApplicationFormBackorderStatus: {
    NotYet: {
      code: "not_yet",
      name: "not_yet",
      label: "Not Yet",
    },
    Already: {
      code: "already",
      name: "already",
      label: "Already",
    }
  },
  DamagedPart: {
    RainGutter: {
      code: "rain_gutter",
      name: "rain_gutter",
      label: "Rain Gutter",
    },
    Carport: {
      code: "carport",
      name: "carport",
      label: "Carport",
    },
    Stucco: {
      code: "stucco",
      name: "stucco",
      label: "Stucco",
    },
    Eaves: {
      code: "eaves",
      name: "eaves",
      label: "Eaves",
    },
    RidgeSheetMetal: {
      code: "ridge_sheet_metal",
      name: "ridge_sheet_metal",
      label: "Ridge Sheet Metal",
    },
    CorrugatedSheet: {
      code: "corrugated_sheet",
      name: "corrugated_sheet",
      label: "Corrugated Sheet",
    },
    Tile: {
      code: "tile",
      name: "tile",
      label: "Tile",
    },
    Others: {
      code: "others",
      name: "others",
      label: "Others",
    },
  },
  CollectionExpectationFormat: {
    EveryMonthWeekX: {
      code: "every_month_week_x",
      name: "every_month_week_x",
      label: "Every Month Week X",
    },
    EveryMonthDayX: {
      code: "every_month_day_x",
      name: "every_month_day_x",
      label: "Every Month Day X",
    },
  },
  ViewType: {
    List: {
      code: "list",
      name: "list",
      label: "List",
      icon: "list_alt",
    },
    Status: {
      code: "status",
      name: "status",
      label: "Status",
      icon: "view_agenda",
    },
    Progress: {
      code: "progress",
      name: "progress",
      label: "Progress",
      icon: "donut_small",
    },
  },
  EstimationUnit: {
    Meter: {
      code: "meter",
      name: "meter",
      label: "Meter",
    },
    SquareMeter: {
      code: "square_meter",
      name: "square_meter",
      label: "Square Meter",
    },
    Piece: {
      code: "piece",
      name: "piece",
      label: "Piece",
    },
    Formula: {
      code: "formula",
      name: "formula",
      label: "Formula",
    },
    HumanWork: {
      code: "human_work",
      name: "human_work",
      label: "Human Work",
    },
  },
  Floor: {
    _others: {
      code: "floor_others",
      name: "floor_others",
      label: "Others",
    },
    _0: {
      code: "floor_0",
      name: "floor_0",
      label: "1Floor",
    },
    _1: {
      code: "floor_1",
      name: "floor_1",
      label: "2Floor",
    },
    _2: {
      code: "floor_2",
      name: "floor_2",
      label: "3Floor",
    }
  },
  PaymentMethod: {
    ContractCompletion: {
      code: "contract_completion",
      name: "contract_completion",
      label: "Contract Completion",
    },
    ConstructionPartial: {
      code: "construction_partial",
      name: "construction_partial",
      label: "Construction Partial",
    },
    ConstructionCompletion: {
      code: "construction_completion",
      name: "construction_completion",
      label: "Construction Completion",
    },
  }
};
export const ProjectHistory = {
  Type: {
    None: {
      code: "none",
      name: "project_history_none",
      label: "None",
    },
    FlyerBeforeCollection: {
      code: "flyer_before_collection",
      name: "daily_report_flyer_before_collection",
      label: "flyer_before_collection",
    },
    FlyerConsideration: {
      code: "flyer_consideration",
      name: "daily_report_flyer_consideration",
      label: "flyer_consideration",
    },
    FlyerAbsence: {
      code: "flyer_absence",
      name: "daily_report_flyer_absence",
      label: "flyer_absence",
    },
    FlyerRefusal: {
      code: "flyer_refusal",
      name: "daily_report_flyer_refusal",
      label: "flyer_refusal",
    },
    FlyerNotApplicable: {
      code: "flyer_not_applicable",
      name: "daily_report_flyer_not_applicable",
      label: "flyer_not_applicable",
    },
    FlyerUnoccupiedHouse: {
      code: "flyer_unoccupied_house",
      name: "daily_report_flyer_unoccupied_house",
      label: "flyer_unoccupied_house",
    },
    FlyerOthers: {
      code: "flyer_others",
      name: "daily_report_flyer_others",
      label: "flyer_others",
    },
    FlyerWishForRepair: {
      code: "flyer_wish_for_repair",
      name: "daily_report_flyer_wish_for_repair",
      label: "flyer_wish_for_repair",
    },
    BeforeOnsiteResearch: {
      code: "before_onsite_research",
      name: "daily_report_before_onsite_research",
      label: "before_onsite_research",
    },
    OnsiteResearch: {
      code: "onsite_research",
      name: "daily_report_onsite_research",
      label: "onsite_research",
    },
    DrawingAndEstimationCreate: {
      code: "drawing_and_estimation_create",
      name: "daily_report_drawing_and_estimation_create",
      label: "Drawing And Estimation Create",
    },
    DrawingAndEstimationApproval: {
      code: "drawing_and_estimation_approval",
      name: "daily_report_drawing_and_estimation_approval",
      label: "Drawing And Estimation Approval",
    },
    InsuranceApplicationSubmission: {
      code: "insurance_application_submission",
      name: "daily_report_insurance_application_submission",
      label: "Insurance Application Submission",
    },
    DrawingAndEstimationAdjustment: {
      code: "drawing_and_estimation_adjustment",
      name: "daily_report_drawing_and_estimation_adjustment",
      label: "Drawing And Estimation Adjustment",
    },
    DrawingAndEstimationAdjustmentApproval: {
      code: "drawing_and_estimation_adjustment_approval",
      name: "daily_report_drawing_and_estimation_adjustment_approval",
      label: "Drawing And Estimation Adjustment_approval",
    },
    ContractContentConfirmation: {
      code: "contract_content_confirmation",
      name: "daily_report_contract_content_confirmation",
      label: "Contract Content Confirmation",
    },
    ConstructionAdjustment: {
      code: "construction_adjustment",
      name: "daily_report_construction_adjustment",
      label: "Construction Adjustment",
    },
    Construction: {
      code: "construction",
      name: "daily_report_construction",
      label: "Construction",
    },
    CompletionReportCreate: {
      code: "completion_report_create",
      name: "daily_report_completion_report_create",
      label: "Completion Report Create",
    },
    CompletionReport: {
      code: "completion_report",
      name: "daily_report_completion_report",
      label: "Completion Report",
    },
    WaitingForPayment: {
      code: "waiting_for_payment",
      name: "daily_report_waiting_for_payment",
      label: "Waiting For Payment",
    },
    PaymentReceived: {
      code: "payment_received",
      name: "daily_report_payment_received",
      label: "Payment Received",
    },
  },
};
export const Material = {
  CsvColumn: {
    Name0: {
      code: "name0",
      name: "name0",
      label: '品名大項目',
      isAmount: false,
    },
    Name1: {
      code: "name1",
      name: "name1",
      label: '品名小項目',
      isAmount: false,
    },
    Manufacturer: {
      code: "manufacturer",
      name: "manufacturer",
      label: 'メーカー',
      isAmount: false,
    },
    EavesReplacementAmount: {
      code: "eaves_replacement_amount",
      name: "eaves_replacement_amount",
      label: '軒とい交換',
      isAmount: true,
    },
    JointAmount: {
      code: "joint_amount",
      name: "joint_amount",
      label: '継手',
      isAmount: true,
    },
    WaterStopAmount: {
      code: "water_stop_amount",
      name: "water_stop_amount",
      label: '水止',
      isAmount: true,
    },
    BendAmount: {
      code: "bend_amount",
      name: "bend_amount",
      label: '曲り',
      isAmount: true,
    },
    MetalFittingAmount: {
      code: "metal_fitting_amount",
      name: "metal_fitting_amount",
      label: '金具',
      isAmount: true,
    },
    WaterCollectorAmount: {
      code: "water_collector_amount",
      name: "water_collector_amount",
      label: '集水器',
      isAmount: true,
    },
    VerticalSillAmount: {
      code: "vertical_sill_amount",
      name: "vertical_sill_amount",
      label: 'たてとい',
      isAmount: true,
    },
    ElbowAmount: {
      code: "elbow_amount",
      name: "elbow_amount",
      label: 'エルボ',
      isAmount: true,
    },
  }
};
export const CoverEstimation = {
  Type: {
    RepairWork: {
      code: "repair_work",
      name: "repair_work",
      label: '雨樋修繕載せ替え工事',
      completionReportLabel: 'working_repair_work_report_create',
      processName: "progress_repair_work_at",
      estimationDisplay: true,
      estimatioLabel: '雨樋修繕載せ替え工事',
    },
    ExchangeWork: {
      code: "exchange_work",
      name: "exchange_work",
      label: '雨樋修繕打ち換え工事',
      completionReportLabel: 'working_exchange_work_report_create',
      processName: "progress_exchange_work_at",
      estimationDisplay: true,
      estimatioLabel: '雨樋修繕打ち換え工事',
    },
    ScaffoldInstallation: {
      code: "scaffold_installation",
      name: "scaffold_installation",
      label: '足場設置工事',
      completionReportLabel: 'working_scaffold_installation_report_create',
      processName: "progress_scaffold_installation_at",
      estimationDisplay: true,
      estimatioLabel: '足場設置・解体工事',
    },
    ScaffoldDismantling: {
      code: "scaffold_dismantling",
      name: "scaffold_dismantling",
      label: '足場解体工事',
      completionReportLabel: 'working_scaffold_dismantling_report_create',
      processName: "progress_scaffold_dismantling_at",
      estimationDisplay: false,
      estimatioLabel: '足場解体工事',
    },
    PaintingWork: {
      code: "painting_work",
      name: "painting_work",
      label: '塗装工事',
      completionReportLabel: 'working_painting_work_report_create',
      processName: "progress_painting_work_at",
      estimationDisplay: true,
      estimatioLabel: '塗装工事',
    },
    SheetMetalWork: {
      code: "sheet_metal_work",
      name: "sheet_metal_work",
      label: '板金工事',
      completionReportLabel: 'working_painting_work_report_create',
      processName: "progress_sheet_metal_work_at",
      estimationDisplay: true,
      estimatioLabel: '板金工事',
    },
  }
};
export const BreakdownEstimation = {
  Type: {
    Template: {
      code: "template",
      name: "template",
      label: 'Template',
    },
    Record: {
      code: "record",
      name: "record",
      label: 'Record',
    },
  }
};
export const PartsOrderRequest = {
  Type: {
    Template: {
      code: "template",
      name: "template",
      label: 'Template',
    },
    Record: {
      code: "record",
      name: "record",
      label: 'Record',
    },
  }
};
export const CompletionReport = {
  Type: {
    Progress: {
      code: "progress",
      name: "途中経過",
      label: '途中経過',
    },
    Completion: {
      code: "completion",
      name: "完了",
      label: '完了',
    },
  },
  ConstructionConfirmation: {
    _0: {
      code: "_0",
      name: '廃材及びゴミ撤去の確認を行いました。',
      label: '廃材及びゴミ撤去の確認を行いました。'
    },
    _1: {
      code: "_1",
      name: '工具及び備品撤去の確認を行いました。',
      label: '工具及び備品撤去の確認を行いました。'
    },
    _2: {
      code: "_2",
      name: '作業場所及び周辺の清掃を行いました。',
      label: '作業場所及び周辺の清掃を行いました。'
    },
  },
  CustomerConfirmation: {
    _0: {
      code: "_0",
      name: '廃材及びゴミ撤去の確認を行いました。',
      label: '廃材及びゴミ撤去の確認を行いました。'
    },
    _1: {
      code: "_1",
      name: '工具及び備品撤去の確認を行いました。',
      label: '工具及び備品撤去の確認を行いました。'
    },
    _2: {
      code: "_2",
      name: '工具及び備品撤去の確認を行いました。',
      label: '作業場所及び周辺の清掃を行いました。'
    },
  },
};
export const ChatRoom = {
  Type: {
    Group: {
      code: "group",
      name: "group",
      label: "Group",
    },
    Project: {
      code: "project",
      name: "project",
      label: "Project",
    },
    Room: {
      code: "room",
      name: "room",
      label: "Room",
    },
  },
  Role: {
    Guest: {
      code: "guest",
      name: "guest",
      label: "Guest",
    },
    InvitationValid: {
      code: "invitation_valid",
      name: "invitation_valid",
      label: "Invitation Valid",
    },
    Participant: {
      code: "participant",
      name: "participant",
      label: "Participant",
    },
    Owner: {
      code: "owner",
      name: "owner",
      label: "Owner",
    },
  },
  Config: {
    Content: {
      max: 10000
    }
  }
};
export const ChatMessage = {
  Type: {
    PureText: {
      code: "pure_text",
      name: "pure_text",
      label: "Pure Text",
    },
    Link: {
      code: "link",
      name: "link",
      label: "Link",
    },
    Contact: {
      code: "contact",
      name: "contact",
      label: "Contact",
    },
    Album: {
      code: "album",
      name: "album",
      label: "Album",
    },
    Note: {
      code: "note",
      name: "note",
      label: "Note",
    },
    MixedFile: {
      code: "mixed_file",
      name: "mixed_file",
      label: "Mixed File",
    },
    FromFile: {
      code: "from_file",
      name: "from_file",
      label: "From File",
    },
    CaptureImage: {
      code: "capture_image",
      name: "capture_image",
      label: "Capture Image",
    },
    Voice: {
      code: "voice",
      name: "voice",
      label: "Voice",
    },
    Calendar: {
      code: "calendar",
      name: "calendar",
      label: "Calendar",
    },
    Todo: {
      code: "todo",
      name: "todo",
      label: "Todo",
    },
  },
};
export const Notification = {
  Origin: {
    Notification: {
      code: "notification",
      name: "notification",
      label: "Notification",
    },
    Messenger: {
      code: "messenger",
      name: "messenger",
      label: "Messenger",
    },
  },
  Type: {
    Event: {
      code: "event",
      name: "event",
      label: "Event",
      notificationTitle: "notification_title_event",
      notificationContent: "notification_content_event",
      notificationContentAllDay: "notification_content_event_all_day",
    },
    Calendar: {
      code: "calendar",
      name: "calendar",
      label: "Calendar",
      notificationTitle: "notification_title_calendar",
      notificationContent: "notification_content_calendar",
      notificationContentAllDay: "notification_content_calendar_all_day",
    },
    Todo: {
      code: "todo",
      name: "todo",
      label: "Todo",
      notificationTitle: "notification_title_todo",
      notificationContent: "notification_content_todo",
      notificationContentAllDay: "notification_content_todo_all_day",
    },
    FriendRequestAccepted: {
      code: "friend_request_accepted",
      name: "friend_request_accepted",
      label: "friend_request_accepted",
      notificationTitle: "notification_title_friend_request_accepted",
      notificationContent: "notification_content_friend_request_accepted",
    },
    ChatRoomCreated: {
      code: "chat_room_created",
      name: "chat_room_created",
      label: "Chat Room Created",
      notificationTitle: "notification_title_chat_room_created",
      notificationContent: "notification_content_chat_room_created",
    },
  },
  PushType: {
    Instantly: {
      code: "instantly",
      name: "instantly",
      label: "Instantly",
    },
    Timer: {
      code: "timer",
      name: "timer",
      label: "Timer",
    },
  },
};
export const WebSocket = {
  Action: {
    ChatMessagePost: {
      code: "chat_message_post",
      name: "chat_message_post",
      label: "Chat Message Post",
    },
    ChatMessageFocus: {
      code: "chat_message_focus",
      name: "chat_message_focus",
      label: "Chat Message Focus",
    },
    ChatMessageDelete: {
      code: "chat_message_delete",
      name: "chat_message_delete",
      label: "Chat Message Delete",
    },
    ServerSystemMessage: {
      code: "server_system_message",
      name: "server_system_message",
      label: "Server System Message",
    },
  },
};
export const BankAccount = {
  Brand: {
    Yuucho: {
      code: "yuucho",
      name: "yuucho",
      label: "ゆうちょ銀行",
    },
  },
};
export const Expense = {
  Status: {
    OnHold: {
      code: "on_hold",
      name: "on_hold",
      label: "On Hold",
    },
    OnRequest: {
      code: "on_request",
      name: "on_request",
      label: "On Request",
    },
    Approved: {
      code: "approved",
      name: "approved",
      label: "Approved",
    },
    Rejected: {
      code: "rejected",
      name: "rejected",
      label: "Rejected",
    },
  },
};
export const TimeCard = {
  Type: {
    AtWork: {
      code: "at_work",
      name: "at_work",
      label: "At Work",
      color: "#97D077",
      timeName: "at_work_time",
    },
    LeavingWork: {
      code: "leaving_work",
      name: "leaving_work",
      label: "Leaving Work",
      color: "#FFB570",
      timeName: "leaving_work_time",
    },
    TakingABreak: {
      code: "taking_a_break",
      name: "taking_a_break",
      label: "Taking a Break",
      color: "#1caefd",
      timeName: "taking_a_break_time",
    },
    GettingBack: {
      code: "getting_back",
      name: "getting_back",
      label: "Getting Back",
      color: "#e06c6c",
      timeName: "getting_back_time",
    },
  },
};
export const PaidHoliday = {
  Type: {
    FullDay: {
      code: "full_day",
      name: "full_day",
      label: "full_day",
      calculateValue: 1,
    },
    HalfDay: {
      code: "half_day",
      name: "half_day",
      label: "half_day",
      calculateValue: 0.5,
    },
  },
};
export const Payslip = {
  Type: {
    Template: {
      code: "template",
      name: "template",
      label: 'Template',
    },
    Record: {
      code: "record",
      name: "record",
      label: 'Record',
    },
  },
  Status: {
    OnHold: {
      code: "on_hold",
      name: "on_hold",
      label: "On Hold",
    },
    OnPresent: {
      code: "on_present",
      name: "on_present",
      label: "On Present",
    },
  },
};

//  components
export const DynamicDialog = {
  Variant: {
    Create: {
      code: "create",
      name: "create",
      label: "create",
    },
    Update: {
      code: "update",
      name: "update",
      label: "Update",
    },
    Show: {
      code: "show",
      name: "show",
      label: "show",
    },
    Confirm: {
      code: "confirm",
      name: "confirm",
      label: "confirm",
    },
    Info: {
      code: "info",
      name: "info",
      label: "info",
    },
    Panel: {
      code: "panel",
      name: "panel",
      label: "panel",
    },
    Manage: {
      code: "manage",
      name: "manage",
      label: "manage",
    },
  },
}
export const AudioRecorder = {
  State: {
    Initialize: {
      code: "initialize",
      name: "initialize",
      label: "Initialize",
    },
    Recording: {
      code: "recording",
      name: "recording",
      label: "Recording",
    },
    Pausing: {
      code: "pausing",
      name: "pausing",
      label: "Pausing",
    },
    Stopped: {
      code: "stopped",
      name: "stopped",
      label: "Stopped",
    },
  },
};
export const Camera = {
  State: {
    Initialize: {
      code: "initialize",
      name: "initialize",
      label: "Initialize",
    },
    Recording: {
      code: "recording",
      name: "recording",
      label: "Recording",
    },
    Stopped: {
      code: "stopped",
      name: "stopped",
      label: "Stopped",
    },
  },
  FacingMode: {
    User: {
      code: "user",
      name: "user",
      label: "User",
    },
    Environment: {
      code: "environment",
      name: "environment",
      label: "Environment",
    },
  }
};
export const ColorPickerAdapter = {
  InternalColors: {
    Color0: {
      code: "#3dc2c8",
      name: "blue",
      label: "#3dc2c8",
      className: "bg-3dc2c8",
    },
    Color1: {
      code: "#2ecc87",
      name: "green",
      label: "#2ecc87",
      className: "bg-2ecc87",
    },
    Color2: {
      code: "#e73b3b",
      name: "red",
      label: "#e73b3b",
      className: "bg-e73b3b",
    },
    Color3: {
      code: "#f35f8c",
      name: "pink",
      label: "#f35f8c",
      className: "bg-f35f8c",
    },
    Color4: {
      code: "#fdc02d",
      name: "yellow",
      label: "#fdc02d",
      className: "bg-fdc02d",
    },
    Color5: {
      code: "#b38bdc",
      name: "purple",
      label: "#b38bdc",
      className: "bg-b38bdc",
    },
  },
};
export const GoogleMap = {
  MarkerType: {
    Point: {
      code: "point",
      name: "project",
      label: "Point",
      icon: "room",
    },
    Polygon: {
      code: "polygon",
      name: "area",
      label: "Polygon",
      icon: "format_shapes",
    },
    None: {
      code: "none",
      name: "none",
      label: "None",
      icon: "radar",
    },
  },
  MapType: {
    Roadmap: {
      code: "roadmap",
      name: "roadmap",
      label: "Roadmap",
    },
    Satellite: {
      code: "satellite",
      name: "satellite",
      label: "Satellite",
    },
    Hybrid: {
      code: "hybrid",
      name: "hybrid",
      label: "Hybrid",
    },
  }
};
export const ProjectCrudForm = {
  Variant: {
    Marker: {
      code: "marker",
      name: "marker",
      label: "Marker",
    },
    All: {
      code: "all",
      name: "all",
      label: "All",
    },
  },
};
export const Avatar = {
  Status: {
    Online: {
      code: "online",
      name: "online",
      label: "Online",
      className: "online",
    },
    Offline: {
      code: "offline",
      name: "offline",
      label: "Offline",
      className: "offline",
    },
  },
};
export const NotificationSetting = {
  Unit: {
    nDaysBefore: {
      code: "n_days_before",
      name: "n_days_before",
      label: "n Days Before",
      allDay: true,
    },
    nWeeksBefore: {
      code: "n_weeks_before",
      name: "n_weeks_before",
      label: "n Weeks Before",
      allDay: true,
    },
    nMinutesBefore: {
      code: "n_minutes_before",
      name: "n_minutes_before",
      label: "n Minutes Before",
      allDay: false,
    },
    nHoursBefore: {
      code: "n_hours_before",
      name: "n_hours_before",
      label: "n Hours Before",
      allDay: false,
    },
  },
};
export const Navigation = {
  RouteType: {
    Public: {
      code: "public",
      name: "public",
      label: "Public",
    },
    Authenticated: {
      code: "authenticated",
      name: "authenticated",
      label: "Authenticated",
    },
    ToAuthenticate: {
      code: "to_authenticate",
      name: "to_authenticat",
      label: "ToAuthenticate",
    },
    AdminAuthenticate: {
        code: "admin_authenticate",
        name: "admin_authenticate",
        label: "AdminAuthenticate",
        },
  },
  Route: {
    Intro: {
      code: "intro",
      name: "intro",
      label: "intro",
      routeTypeCode: "public",
      path: "/intro",
      componentPath: "pages/IntroPage/IntroPage",
      icon: "none",
      isMainMenu: false,
      exact: true,
      backPath: "",
      isRootRoute: true,
      isSubRoute: false,
    },
    SignIn: {
      code: "sign_in",
      name: "sign_in",
      label: "Sign In",
      routeTypeCode: "to_authenticate",
      path: "/sign-in",
      componentPath: "pages/SignInPage/SignInPage",
      icon: "none",
      isMainMenu: false,
      exact: true,
      backPath: "",
      isRootRoute: false,
      isSubRoute: false,
    },
    ForgotPassword: {
      code: "forgot_password",
      name: "forgot_password",
      label: "Forgot Password",
      routeTypeCode: "to_authenticate",
      path: "/forgot-password",
      componentPath: "pages/ForgotPasswordPage/ForgotPasswordPage",
      icon: "none",
      isMainMenu: false,
      exact: true,
      backPath: "",
      isRootRoute: false,
      isSubRoute: false,
    },
    ResetPassword: {
      code: "reset_password",
      name: "reset_password",
      label: "Reset Password",
      routeTypeCode: "to_authenticate",
      path: "/reset-password",
      componentPath: "pages/ResetPasswordPage/ResetPasswordPage",
      icon: "none",
      isMainMenu: false,
      exact: true,
      backPath: "",
      isRootRoute: false,
      isSubRoute: false,
    },
    SignUp: {
      code: "sign_up",
      name: "sign_up",
      label: "Sign Up",
      routeTypeCode: "public",
      path: "/sign-up",
      componentPath: "pages/SignUpPage/SignUpPage",
      icon: "none",
      isMainMenu: false,
      exact: true,
      backPath: "",
      isRootRoute: false,
      isSubRoute: false,
    },
    Home: {
      code: "home",
      name: "home",
      label: "Home",
      routeTypeCode: "authenticated",
      path: "/",
      componentPath: "pages/HomePage/HomePage",
      icon: "home",
      isMainMenu: true,
      exact: true,
      backPath: "",
      isRootRoute: true,
      isSubRoute: false,
    },
    User_Setting: {
      code: "user_settings",
      name: "user_settings",
      label: "User Setting",
      routeTypeCode: "authenticated",
      path: "/setting/user",
      componentPath: "pages/UserSettingPage/UserSettingPage",
      icon: "none",
      isMainMenu: false,
      exact: true,
      backPath: "",
      isRootRoute: false,
      isSubRoute: false,
    },
    Profiles: {
      code: "profiles",
      name: "profiles",
      label: "Profiles",
      routeTypeCode: "authenticated",
      path: "/profiles",
      componentPath: "pages/ProfilesPage/ProfilesPage",
      icon: "none",
      isMainMenu: false,
      exact: true,
      backPath: "",
      isRootRoute: false,
      isSubRoute: false,
    },
    Profile: {
      code: "profile",
      name: "profile",
      label: "Profile",
      routeTypeCode: "authenticated",
      path: "/profile/:profile_id",
      componentPath: "pages/ProfilePage/ProfilePage",
      icon: "none",
      isMainMenu: false,
      exact: true,
      backPath: "",
      isRootRoute: false,
      isSubRoute: false,
    },
    Invite: {
      code: "invite",
      name: "invite",
      label: "invite",
      routeTypeCode: "authenticated",
      path: "/invite/:referral_code",
      componentPath: "pages/InvitePage/InvitePage",
      icon: "none",
      isMainMenu: false,
      exact: true,
      backPath: "",
      isRootRoute: false,
      isSubRoute: false,
    },
    SearchProfiles: {
      code: "search_profile",
      name: "search_profiles",
      label: "Search Profiles",
      routeTypeCode: "authenticated",
      path: "/search/profiles",
      componentPath: "pages/SearchProfilesPage/SearchProfilesPage",
      icon: "none",
      isMainMenu: false,
      exact: true,
      backPath: "",
      isRootRoute: false,
      isSubRoute: false,
    },
    LocationMap: {
      code: "location_map",
      name: "location_map",
      label: "location_map",
      routeTypeCode: "authenticated",
      path: "/location-map/:lat/:lng",
      componentPath: "pages/LocationMapPage/LocationMapPage",
      icon: "none",
      isMainMenu: false,
      exact: true,
      backPath: "",
      isRootRoute: true,
      isSubRoute: false,
    },
    AppServices: {
      code: "app_services",
      name: "app_services",
      label: "App Services",
      routeTypeCode: "authenticated",
      path: "/app-services",
      componentPath: "pages/AppServicesPage/AppServicesPage",
      icon: "none",
      isMainMenu: false,
      exact: true,
      backPath: "",
      isRootRoute: false,
      isSubRoute: false,
    },
    FavoriteAppServices: {
      code: "favorite_app_services",
      name: "favorite_app_services",
      label: "Favorite App Services",
      routeTypeCode: "authenticated",
      path: "/favorite-app-services",
      componentPath: "pages/FavoriteAppServicesPage/FavoriteAppServicesPage",
      icon: "none",
      isMainMenu: false,
      exact: true,
      backPath: "",
      isRootRoute: false,
      isSubRoute: false,
    },
    Group: {
      code: "group",
      name: "group",
      label: "Group",
      routeTypeCode: "authenticated",
      path: "/group/:group_id",
      componentPath: "pages/GroupPage/GroupPage",
      icon: "none",
      isMainMenu: false,
      exact: true,
      backPath: "",
      isRootRoute: false,
      isSubRoute: false,
    },
    GroupCreate: {
      code: "group_create",
      name: "group_create",
      label: "Group Create",
      routeTypeCode: "authenticated",
      path: "/group-create",
      componentPath: "pages/GroupCreatePage/GroupCreatePage",
      icon: "none",
      isMainMenu: false,
      exact: true,
      backPath: "",
      isRootRoute: false,
      isSubRoute: false,
    },
    GroupList: {
      code: "group_list",
      name: "group_list",
      label: "Group List",
      routeTypeCode: "authenticated",
      path: "/group-list",
      componentPath: "pages/GroupListPage/GroupListPage",
      icon: "none",
      isMainMenu: false,
      exact: true,
      backPath: "",
      isRootRoute: false,
      isSubRoute: false,
    },
    GroupInvitation: {
      code: "group_list",
      name: "group_list",
      label: "Group List",
      routeTypeCode: "public",
      path: "/group-invitation/:group_id/:invitation_code",
      componentPath: "pages/GroupInvitationPage/GroupInvitationPage",
      icon: "none",
      isMainMenu: false,
      exact: true,
      backPath: "",
      isRootRoute: false,
      isSubRoute: false,
    },
    Chat: {
      code: "chat",
      name: "chat",
      label: "chat",
      routeTypeCode: "authenticated",
      path: "/chat",
      componentPath: "pages/ChatPage/ChatPage",
      icon: "message",
      isMainMenu: true,
      exact: true,
      backPath: "/",
      isRootRoute: true,
      isSubRoute: false,
    },
    ChatRoom: {
      code: "chat_room",
      name: "chat_room",
      label: "chat_room",
      routeTypeCode: "authenticated",
      path: "/chat-room/:chat_room_id",
      componentPath: "pages/ChatRoomPage/ChatRoomPage",
      icon: "none",
      isMainMenu: false,
      exact: false,
      backPath: "/chat",
      isRootRoute: false,
      isSubRoute: false,
    },
    ChatRoomCreate: {
      code: "chat_room_create",
      name: "chat_room_create",
      label: "chat_room_create",
      routeTypeCode: "authenticated",
      path: "/chat-room/create",
      componentPath: "pages/ChatRoomCreatePage/ChatRoomCreatePage",
      icon: "none",
      isMainMenu: false,
      exact: false,
      backPath: "",
      isRootRoute: false,
      isSubRoute: false,
    },
    ChatRoomInvitation: {
      code: "chat_room_invitation",
      name: "chat_room_invitation",
      label: "chat_room_invitation",
      routeTypeCode: "public",
      path: "/chat-room-invitation/:chat_room_id/:invitation_code",
      componentPath: "pages/ChatRoomInvitationPage/ChatRoomInvitationPage",
      icon: "none",
      isMainMenu: false,
      exact: false,
      backPath: "",
      isRootRoute: false,
      isSubRoute: false,
    },
    FavoriteChatRooms: {
      code: "favorite_chat_rooms",
      name: "favorite_chat_rooms",
      label: "Favorite Chat Rooms",
      routeTypeCode: "authenticated",
      path: "/favorite-chat-rooms",
      componentPath: "pages/FavoriteChatRoomsPage/FavoriteChatRoomsPage",
      icon: "home",
      isMainMenu: false,
      exact: true,
      backPath: "",
      isRootRoute: false,
      isSubRoute: false,
    },
    Calendar: {
      code: "calendar",
      name: "calendar",
      label: "Calendar",
      routeTypeCode: "authenticated",
      path: "/calendar",
      componentPath: "pages/CalendarPage/CalendarPage",
      icon: "calendar_today",
      isMainMenu: true,
      exact: true,
      backPath: "",
      isRootRoute: true,
      isSubRoute: false,
    },
    Todo: {
      code: "todo",
      name: "todo",
      label: "Todo",
      routeTypeCode: "authenticated",
      path: "/todo",
      componentPath: "pages/TodoPage/TodoPage",
      icon: "assignment",
      isMainMenu: true,
      exact: true,
      backPath: "",
      isRootRoute: true,
      isSubRoute: false,
    },
    Contact: {
      code: "contact",
      name: "contact",
      label: "contact",
      routeTypeCode: "authenticated",
      path: "/contact",
      componentPath: "pages/ContactPage/ContactPage",
      icon: "perm_contact_calendar",
      isMainMenu: true,
      exact: true,
      backPath: "",
      isRootRoute: true,
      isSubRoute: false,
    },

    // reform management routes
    ManagementGroupList: {
      code: "management_group_list",
      name: "management_group_list",
      label: "management_group_list",
      routeTypeCode: "authenticated",
      path: "/reform/management/group-list/:group_id",
      componentPath: "reformManagementPages/GroupListPage/GroupListPage",
      icon: "groups",
      isMainMenu: false,
      exact: false,
      backPath: "/reform/menu/group/:group_id",
      isRootRoute: false,
      isSubRoute: false,
    },
    ManagementProfileList: {
      code: "management_profile_list",
      name: "management_profile_list",
      label: "management_profile_list",
      routeTypeCode: "authenticated",
      path: "/reform/management/profile-list/:group_id",
      componentPath: "reformManagementPages/ProfileListPage/ProfileListPage",
      icon: "diversity_3",
      isMainMenu: false,
      exact: false,
      backPath: "/reform/menu/:group_id",
      isRootRoute: false,
      isSubRoute: false,
    },
    ManagementTimeCardsByMonth: {
      code: "management_daily_report_list",
      name: "management_daily_report_list",
      label: "management_daily_report_list",
      routeTypeCode: "authenticated",
      path: "/reform/management/time-cards-by-month/:group_id/:profile_id",
      componentPath: "reformManagementPages/TimeCardsByMonthPage/TimeCardsByMonthPage",
      icon: "local_convenience_store",
      isMainMenu: false,
      exact: false,
      backPath: "",
      isRootRoute: false,
      isSubRoute: false,
    },
    ManagementDailyReportList: {
      code: "management_daily_report_list",
      name: "management_daily_report_list",
      label: "management_daily_report_list",
      routeTypeCode: "authenticated",
      path: "/reform/management/daily-report-list/:group_id/:profile_id",
      componentPath: "reformManagementPages/DailyReportListPage/DailyReportListPage",
      icon: "summarize",
      isMainMenu: false,
      exact: false,
      backPath: "",
      isRootRoute: false,
      isSubRoute: false,
    },
    ManagementDailyReport: {
      code: "management_daily_report",
      name: "management_daily_report",
      label: "management_daily_report",
      routeTypeCode: "authenticated",
      path: "/reform/management/daily-report/:group_id/:profile_id/:date",
      componentPath: "reformManagementPages/DailyReportPage/DailyReportPage",
      icon: "summarize",
      isMainMenu: false,
      exact: false,
      backPath: "/reform/management/daily-report-list/:group_id/:profile_id",
      isRootRoute: false,
      isSubRoute: false,
    },

    ManagementPayslip: {
      code: "management_payslip",
      name: "management_payslip",
      label: "management_payslip",
      routeTypeCode: "authenticated",
      path: "/reform/management/payslip/:group_id/:profile_id",
      componentPath: "reformManagementPages/PayslipPage/PayslipPage",
      icon: "receipt",
      isMainMenu: false,
      exact: false,
      backPath: "",
      isRootRoute: false,
      isSubRoute: false,
    },

    // reform routes
    ReformMenuGroup: {
      code: "reform_menu_group",
      name: "reform_menu_group",
      label: "reform_menu_group",
      routeTypeCode: "authenticated",
      path: "/reform/menu/group/:group_id",
      componentPath: "reformPages/MenuGroupPage/MenuGroupPage",
      icon: "groups",
      isMainMenu: false,
      exact: true,
      backPath: "",
      isRootRoute: false,
      isSubRoute: false,
    },
    ReformMap: {
      code: "reform_map",
      name: "reform_map",
      label: "reform_map",
      routeTypeCode: "authenticated",
      path: "/reform/map/:group_id",
      componentPath: "reformPages/ReformMapPage/ReformMapPage",
      icon: "map",
      isMainMenu: false,
      exact: false,
      backPath: "/reform/menu/:group_id",
      isRootRoute: false,
      isSubRoute: false,
    },
    InsuranceCompanyList: {
      code: "insurance_company_list",
      name: "insurance_company_list",
      label: "insurance_company_list",
      routeTypeCode: "authenticated",
      path: "/reform/insurance-company-list/:group_id",
      componentPath: "reformPages/InsuranceCompanyListPage/InsuranceCompanyListPage",
      icon: "business",
      isMainMenu: false,
      exact: false,
      backPath: "/reform/menu/:group_id",
      isRootRoute: false,
      isSubRoute: false,
    },
    MaterialCompanyList: {
      code: "material_company_list",
      name: "material_company_list",
      label: "material_company_list",
      routeTypeCode: "authenticated",
      path: "/reform/material-company-list/:group_id",
      componentPath: "reformPages/MaterialCompanyListPage/MaterialCompanyListPage",
      icon: "business",
      isMainMenu: false,
      exact: false,
      backPath: "/reform/menu/:group_id",
      isRootRoute: false,
      isSubRoute: false,
    },
    ProjectList: {
      code: "project_list",
      name: "project_list",
      label: "project_list",
      routeTypeCode: "authenticated",
      path: "/reform/project-list/:group_id",
      componentPath: "reformPages/ProjectListPage/ProjectListPage",
      icon: "approval",
      isMainMenu: false,
      exact: false,
      backPath: "/reform/menu/:group_id",
      isRootRoute: false,
      isSubRoute: false,
    },
    ProjectListList: {
      code: "project_list_list",
      name: "project_list_list",
      label: "project_list_list",
      routeTypeCode: "authenticated",
      path: "/reform/project-list/:group_id?viewTypeCode=list",
      componentPath: "reformPages/ProjectListPage/ProjectListPage",
      icon: "list_alt",
      isMainMenu: false,
      exact: false,
      backPath: "/reform/menu/:group_id",
      isRootRoute: false,
      isSubRoute: true,
    },
    ProjectListStatus: {
      code: "project_list_status",
      name: "project_list_status",
      label: "project_list_status",
      routeTypeCode: "authenticated",
      path: "/reform/project-list/:group_id?viewTypeCode=status",
      componentPath: "reformPages/ProjectListPage/ProjectListPage",
      icon: "view_agenda",
      isMainMenu: false,
      exact: false,
      backPath: "/reform/menu/:group_id",
      isRootRoute: false,
      isSubRoute: true,
    },
    ProjectListProgress: {
      code: "project_list_progress",
      name: "project_list_progress",
      label: "project_list_progress",
      routeTypeCode: "authenticated",
      path: "/reform/project-list/:group_id?viewTypeCode=progress",
      componentPath: "reformPages/ProjectListPage/ProjectListPage",
      icon: "donut_small",
      isMainMenu: false,
      exact: false,
      backPath: "/reform/menu/:group_id",
      isRootRoute: false,
      isSubRoute: true,
    },
    ApprovalPairList: {
      code: "approval_pair_list",
      name: "approval_pair_list",
      label: "approval_pair_list",
      routeTypeCode: "authenticated",
      path: "/reform/approval-pair-list/:group_id",
      componentPath: "reformPages/ApprovalPairListPage/ApprovalPairListPage",
      icon: "spellcheck",
      isMainMenu: false,
      exact: false,
      backPath: "/reform/menu/group/:group_id",
      isRootRoute: false,
      isSubRoute: false,
    },
    MaterialList: {
      code: "material_list",
      name: "material_list",
      label: "material_list",
      routeTypeCode: "authenticated",
      path: "/reform/material-list/:group_id",
      componentPath: "reformPages/MaterialListPage/MaterialListPage",
      icon: "token",
      isMainMenu: false,
      exact: false,
      backPath: "/reform/menu/document/:group_id",
      isRootRoute: false,
      isSubRoute: false,
    },
    BreakdownEstimationTemplateList: {
      code: "breakdown_estimation_template_list",
      name: "breakdown_estimation_template_list",
      label: "breakdown_estimation_template_list",
      routeTypeCode: "authenticated",
      path: "/reform/breakdown-estimation-template-list/:group_id",
      componentPath: "reformPages/BreakdownEstimationTemplateListPage/BreakdownEstimationTemplateListPage",
      icon: "token",
      isMainMenu: false,
      exact: false,
      backPath: "/reform/menu/document/:group_id",
      isRootRoute: false,
      isSubRoute: false,
    },
    PartsOrderRequestTemplateList: {
      code: "parts_order_request_template_list",
      name: "parts_order_request_template_list",
      label: "parts_order_request_template_list",
      routeTypeCode: "authenticated",
      path: "/reform/parts-order-request-template-list/:group_id",
      componentPath: "reformPages/PartsOrderRequestTemplateListPage/PartsOrderRequestTemplateListPage",
      icon: "token",
      isMainMenu: false,
      exact: false,
      backPath: "/reform/menu/document/:group_id",
      isRootRoute: false,
      isSubRoute: false,
    },
    TimeCard: {
      code: "time_card",
      name: "time_card",
      label: "TimeCard",
      routeTypeCode: "authenticated",
      path: "/reform/time-card/:group_id",
      componentPath: "reformPages/TimeCardPage/TimeCardPage",
      icon: "local_convenience_store",
      isMainMenu: false,
      exact: false,
      backPath: "/reform/menu/:group_id",
      isRootRoute: false,
      isSubRoute: false,
    },
    TimeCardsByMonth: {
      code: "time_cards_by_month",
      name: "time_cards_by_month",
      label: "time_cards_by_month",
      routeTypeCode: "authenticated",
      path: "/reform/time-cards-by-month/:group_id",
      componentPath: "reformPages/TimeCardsByMonthPage/TimeCardsByMonthPage",
      icon: "local_convenience_store",
      isMainMenu: false,
      exact: false,
      backPath: "/reform/menu/:group_id",
      isRootRoute: false,
      isSubRoute: false,
    },
    DailyReportListPage: {
      code: "daily_report_list",
      name: "daily_report_list",
      label: "daily_report_list",
      routeTypeCode: "authenticated",
      path: "/reform/daily-report-list/:group_id",
      componentPath: "reformPages/DailyReportListPage/DailyReportListPage",
      icon: "summarize",
      isMainMenu: false,
      exact: false,
      backPath: "/reform/menu/:group_id",
      isRootRoute: false,
      isSubRoute: false,
    },
    DailyReportPage: {
      code: "daily_report",
      name: "daily_report",
      label: "daily_report",
      routeTypeCode: "authenticated",
      path: "/reform/daily-report/:group_id/:date",
      componentPath: "reformPages/DailyReportPage/DailyReportPage",
      icon: "summarize",
      isMainMenu: false,
      exact: false,
      backPath: "/reform/daily-report-list/:group_id",
      isRootRoute: false,
      isSubRoute: false,
    },
    ExpenseHistoryList: {
      code: "expense_history_list",
      name: "expense_history_list",
      label: "expense_history_list",
      routeTypeCode: "authenticated",
      path: "/reform/expense-history-list/:group_id",
      componentPath: "reformPages/ExpenseHistoryListPage/ExpenseHistoryListPage",
      icon: "monetization_on",
      isMainMenu: false,
      exact: false,
      backPath: "/reform/menu/:group_id",
      isRootRoute: false,
      isSubRoute: false,
    },
    ExpenseRequestList: {
      code: "expense_request_list",
      name: "expense_request_list",
      label: "expense_request_list",
      routeTypeCode: "authenticated",
      path: "/reform/expense-request-list/:group_id",
      componentPath: "reformPages/ExpenseRequestListPage/ExpenseRequestListPage",
      icon: "monetization_on",
      isMainMenu: false,
      exact: false,
      backPath: "/reform/menu/:group_id",
      isRootRoute: false,
      isSubRoute: false,
    },
    ReformMenuPage: {
      code: "reform_menu",
      name: "reform_menu",
      label: "reform_menu",
      routeTypeCode: "authenticated",
      path: "/reform/menu/:group_id",
      componentPath: "reformPages/MenuPage/MenuPage",
      icon: "none",
      isMainMenu: false,
      exact: true,
      backPath: "/",
      isRootRoute: true,
      isSubRoute: false,
    },
    ReformMenuDocument: {
      code: "reform_menu_document",
      name: "reform_menu_document",
      label: "reform_menu_document",
      routeTypeCode: "authenticated",
      path: "/reform/menu/document/:group_id",
      componentPath: "reformPages/MenuDocumentPage/MenuDocumentPage",
      icon: "summarize",
      isMainMenu: false,
      exact: true,
      backPath: "",
      isRootRoute: false,
      isSubRoute: false,
    },
    ReformSplash: {
      code: "reform_splash",
      name: "reform_splash",
      label: "reform_splash",
      routeTypeCode: "authenticated",
      path: "/reform/splash",
      componentPath: "reformPages/ReformSplashPage/ReformSplashPage",
      icon: "none",
      isMainMenu: false,
      exact: true,
      backPath: "/",
    },
    PaidHolidayList: {
      code: "paid_holiday_list",
      name: "paid_holiday_list",
      label: "paid_holiday_list",
      routeTypeCode: "authenticated",
      path: "/reform/paid-holiday-list/:group_id",
      componentPath: "reformPages/PaidHolidayListPage/PaidHolidayListPage",
      icon: "beach_access",
      isMainMenu: false,
      exact: false,
      backPath: "/reform/menu/:group_id",
      isRootRoute: false,
      isSubRoute: false,
    },

    // management routes
    ManagementMenu: {
      code: "management_menu",
      name: "management_menu",
      label: "management_menu",
      routeTypeCode: "authenticated",
      path: "/management/menu",
      componentPath: "managementPages/MenuPage",
      icon: "none",
      isMainMenu: false,
      exact: true,
      backPath: "/",
    },

    // audio routes
    AudioRecorder: {
      code: "audio_recorder",
      name: "audio_recorder",
      label: "audio_recorder",
      routeTypeCode: "authenticated",
      path: "/audio-recorder",
      componentPath: "pages/AudioRecorderPage/AudioRecorderPage",
      icon: "none",
      isMainMenu: false,
      exact: true,
      backPath: "",
      isRootRoute: false,
      isSubRoute: false,
    },
    AudioEdit: {
      code: "audio_edit",
      name: "audio_edit",
      label: "audio_edit",
      routeTypeCode: "authenticated",
      path: "/audio/edit/:id",
      componentPath: "pages/AudioEditPage/AudioEditPage",
      icon: "none",
      isMainMenu: false,
      exact: true,
      backPath: "",
      isRootRoute: false,
      isSubRoute: false,
    },
    FileShow: {
      code: "file_show",
      name: "file_show",
      label: "file_show",
      routeTypeCode: "authenticated",
      path: "/file-show/:id",
      componentPath: "pages/FileShowPage/FileShowPage",
      icon: "none",
      isMainMenu: false,
      exact: true,
      backPath: "",
      isRootRoute: false,
      isSubRoute: false,
    },
    ServiceManagement: {
      code: "service_management",
      name: "service_management",
      label: "service_management",
      routeTypeCode: "admin_authenticate",
      path: "/service-management",
      componentPath: "pages/ServiceManagementPage/ServiceManagementPage",
      icon: "none",
      isMainMenu: false,
      exact: true,
      backPath: "",
      isRootRoute: false,
      isSubRoute: false,
    }
  },
  NativeMenu: {
    FriendRequest: {
      code: "friend_request",
      name: "friend_request",
      label: "Friend Request",
    },
    ChatRoom: {
      code: "chat_room",
      name: "chat_room",
      label: "Chat Room",
    },
    Notification: {
      code: "notification",
      name: "notification",
      label: "Notification",
    },
    Manage: {
      code: "manage_show",
      name: "manage_show",
      label: "manage_show",
      routeTypeCode: "authenticated",
      path: "/manage",
      componentPath: "pages/FileShowPage/FileShowPage",
      icon: "none",
      isMainMenu: false,
      exact: true,
      backPath: "",
      isRootRoute: false,
      isSubRoute: false,
    },
  },
};
export const SideBar = {
  Anchor: {
    Left: {
      code: "left",
      name: "left",
      label: "left",
    },
    Right: {
      code: "right",
      name: "right",
      label: "right",
    },
  },
};
export const MapMarkerSettingsFrom = {
  Variant: {
    Route: {
      code: "route",
      name: "route",
      label: "route",
    },
    Filter: {
      code: "filter",
      name: "filter",
      label: "Filter",
    },
  },
};

export const MapMarkerVariantSettingsFrom = {
  EveryMonthWeekX: {
    code: "every_month_week_x",
    name: "every_month_week_x",
    label: "Every Month Week X",
  },
  EveryMonthDayX: {
    code: "every_month_day_x",
    name: "every_month_day_x",
    label: "Every Month Day X",
  },
  DamageDetection: {
    code: "damage_detection",
    name: "damage_detection",
    label: "damage_detection",
  },
};

// interface
export const Interface = {
  ProfileConfig: {
    week_beginning_code: Config.WeekBeginning.Monday.code,
    time_display_code: Config.TimeDisplay._24h.code,
    actived_chat_room_id: '',
    flyer_collection_expectation_format_code: Project.CollectionExpectationFormat.EveryMonthWeekX.code,
    flyer_collection_expectation_week_code: Config.WeekOfMonth._0.code,
    flyer_collection_expectation_day_code: Config.DayOfMonth._0.code,
    flyer_collection_profile_id: "",
    filter_general_status_codes: Object.values(Project.GeneralStatus).map(x => x.code),
    filter_flyer_status_codes: Object.values(Project.FlyerStatus).map(x => x.code),
  },
};

export const RoleUser = {
    Admin: {
        code: "admin",
        name: "admin",
        label: "Admin",
    },
    User: {
        code: "user",
        name: "user",
        label: "User",
    },
    Manage: {
        code: "manage",
        name: "manage",
        label: "Manage",
    },
}

export const RoleConfig = {
  RoleConfig: {
    admin: {
      code: "role_admin",
      name: "role_admin",
      label: "role_admin",
      backgroundColor: "#ff4d4f",
      fontWeight: 'bold',
      textAlign: 'center',
      color: "#ffffff",
      padding: '8px',
      borderRadius: '4px',
    },
    user: {
      code: "user",
      name: "user",
      label: "entity_user",
      backgroundColor: '#635bff',
      color: "#ffffff",
      padding: '8px',
      borderRadius: '4px',
    },
    manage: {
      code: "manage",
      name: "manage",
      label: "role_manager",
      color: "#ffffff",
      backgroundColor: '#fb9c0c',
      padding: '8px',
      borderRadius: '4px',
      fontWeight: 'bold',
      textAlign: 'center',
    },
    profile: {
      code: "profile",
      name: "profile",
      label: "profile",
      color: "#ffffff",
      backgroundColor: '#15b79f',
      padding: '8px',
      borderRadius: '4px',
      fontWeight: 'bold',
      textAlign: 'center',
    },
    default: {
      code: "default",
      name: "default",
      label: "not_role",
      color: "#ffffff",
      backgroundColor: '#808080',
      padding: '8px',
      borderRadius: '4px',
      fontWeight: 'bold',
      textAlign: 'center',
    },
    active: {
      code: "active",
      name: "active",
      label: "active",
      color: "#ffffff",
      backgroundColor: '#87d068',
      padding: '8px',
      borderRadius: '4px',
      fontWeight: 'bold',
    },
    inactive: {
      code: "inactive",
      name: "inactive",
      label: "inactive",
      color: "#ffffff",
      backgroundColor: '#f50',
      padding: '8px',
      borderRadius: '4px',
      fontWeight: 'bold',
    },
  },
};
